import { APIConfigURLs } from '../apiInterfaceType/apiInterfaceType';

const DELETE_API_URL: APIConfigURLs = {
    designationDelete: {
        url: '/hrm/designation/',
    },
    departmentDelete: {
        url: '/hrm/department/',
    },
    departmentSingleDelete: {
        url: '/hrm/department',
    },
    deleteEmployee: {
        url: '/hrm/employee/employeeIds',
    },
    roleDelete: {
        url: '/hrm/role/',
    },
    singleUserDelete: {
        url: '/hrm/user/',
    },
    deleteSalaryMaster: {
        url: '/hrm/salarycomponent',
    },
    deleteAsset: {
        url: '/hrm/asset/employee-asset',
    },
    deleteChapter: {
        url: '/chapter',
        baseURL: '/elms-app-api/v1',
    },
    deleteHoliday: {
        url: '/holiday-detail',
        baseURL: '/admin/leave-management/v1',
    },
    deleteAssignedEmployee: {
        url: '/employee/course/unassign',
        baseURL: '/elms-app-api/v1',
    },
    deleteCertificateToCourse: {
        url: '/coursecertificate/',
        baseURL: '/elms-app-api/v1',
    },
    deleteAnnouncement: {
        url: 'hrm/email/announcement/delete',
    },
};

export default DELETE_API_URL;
