import { Form, ErrorMessage, Formik } from 'formik';
import FloatLabel from '../../../components/FloatLabel';
import { Button, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { newEmployeeValidationSchema } from './NewAddEmployeeSchema';
import { storeData } from '../../../storage/Storage';
import { disabledDate, getAlphabeticValue } from '../../../hooks/commonHooks';

export default function AddEmployeeMainContent(props: any) {
  const { dropDownList, nextPage, organisationName, handelCancel, setNextPage,
    setImageData, officialEmailEdit, setFormValues, formValues, setDirty } = props;
  const onChangeDate = (date: any, dateString: any, setFieldValue: any): any => {
    setFieldValue('dateOfJoin', dateString);
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      dateOfJoin: dateString,
    }));

  };
  const handleDirty = (dirty: boolean) => {
    if (dirty) {
      setDirty(dirty);
    }
  }
  const handleNames = (event: any, type: string, values: any, setFieldValue: any) => {
    if (type === 'firstName') {
      const eventValue = event.target.value.replace(/[^a-zA-Z\s]/g, '');
      const eventValueString: string = eventValue
      const inputValue: string = eventValueString?.charAt(0)?.toUpperCase() + eventValueString?.slice(1);
      setFieldValue('firstName', inputValue)
      let typedEmailValue: any = `${inputValue?.replace(/\s/g, '')}${inputValue?.length > 0 ? '.' : ''}${values?.lastName?.replace(/\s/g, '')}`.toLowerCase()
      if (values.lastName !== '') {

        typedEmailValue = `${inputValue?.replace(/\s/g, '')}${values?.lastName?.length > 0 ? '.' : ''}${values?.lastName?.charAt(0)?.replace(/\s/g, '')}`?.toLowerCase();
      }

      setFieldValue('officialEmailId', typedEmailValue)
      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        firstName: inputValue,
        officialEmailId: typedEmailValue
      }));
    } else {

      const inputValue = getAlphabeticValue(event.target.value)
      setFieldValue('lastName', inputValue)
      const emailValue: any = `${values?.firstName?.replace(/\s/g, '')}${inputValue?.length > 0 ? '.' : ''}${inputValue?.charAt(0)?.replace(/\s/g, '')}`?.toLowerCase();
      setFieldValue('officialEmailId', emailValue);
      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        lastName: inputValue,
        officialEmailId: emailValue
      }));
    }
  }
  const handleEmailId = (email: string, setFieldValue: any) => {
    if (officialEmailEdit)
      setFieldValue('officialEmailId', email)
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      officialEmailId: email
    }));
  }

  const handleSubmit = (values: any) => {
    if (!nextPage) {
      const formData = new FormData();
      formData.append('employeeProfile', '');
      setImageData(formData); setNextPage(true)
      storeData('initialEmployeeData', values, true);
    }
  }
  const onCancel = () => {
    handelCancel()
    setNextPage(false)
    storeData('initialEmployeeData', '', true);
  }
  return (
    <Formik
      initialValues={formValues}
      onSubmit={handleSubmit}
      // values={initialValues}
      enableReinitialize={true}
      validationSchema={newEmployeeValidationSchema}
    >
      {({ values, handleSubmit, setFieldValue, dirty }) => {
        handleDirty(dirty)
        return (
          <div>
            {!nextPage && (
              <Form>
                <div className="flex w-full mt-8 pb-3 flex-wrap">

                  <div className="w-full flex flex-wrap px-[25px] ">
                    <div className="w-1/2 pt-4">
                      <div className='form-item mr-10'>
                        <FloatLabel label='First Name*' name='firstName' value={values?.firstName}>
                          <Input value={formValues?.firstName} onChange={(e) => handleNames(e, 'firstName', values, setFieldValue)} className='float-control' />
                        </FloatLabel>
                        <ErrorMessage name="firstName" component="div" className="error-message" />

                      </div>
                    </div>
                    <div className="w-1/2 pt-4">
                      <div className='form-item '>
                        <FloatLabel label='Last Name*' name='lastName' value={values?.lastName}>
                          <Input value={formValues?.lastName} onChange={(e) => handleNames(e, 'lastName', values, setFieldValue)} className='float-control' />
                        </FloatLabel>
                        <ErrorMessage name="lastName" component="div" className="error-message" />

                      </div>
                    </div>
                    <div className="w-1/2 mt-8">
                      <div className='form-item relative mr-10'>
                        <FloatLabel label='Official Email ID*' name='officialEmailId' value={values?.officialEmailId}>
                          <Input readOnly={!officialEmailEdit} value={formValues?.officialEmailId} className='float-control ' onChange={(e) => handleEmailId(e.target.value, setFieldValue)} />
                        </FloatLabel>
                        <span className='absolute top-3 right-0 cursor-pointer bg-[#E3E3E3] px-2 text-[#8C8C8C] rounded-sm'>{`@${organisationName?.data?.data?.domainName}`}</span>
                        <ErrorMessage name="officialEmailId" component="div" className="error-message" />

                      </div>
                    </div>
                    <div className="w-1/2 mt-8">
                      <div className='form-item '>
                        <FloatLabel label='Date of Joining*' name='dateOfJoin' value={formValues?.dateOfJoin ? dayjs(values?.dateOfJoin) : null}>
                          <DatePicker placeholder='"' disabledDate={disabledDate} onChange={(date, dateString) => onChangeDate(date, dateString, setFieldValue)}
                            value={formValues?.dateOfJoin ? dayjs(values?.dateOfJoin) : null} className='float-control' />
                        </FloatLabel>
                        <ErrorMessage name="dateOfJoin" component="div" className="error-message" />
                      </div>

                    </div>
                    <div className="w-1/2 mt-8 mb-10 ">
                      <div className='form-item mr-10'>
                        <FloatLabel label='Employement Type*' name='employeeType' value={formValues?.employeeType}>
                          <Select suffixIcon={<span className=" text-[#000] material-symbols-outlined">
                            arrow_drop_down
                          </span>} className='float-control' onChange={(value) => {
                            setFieldValue('employeeType', value)
                            setFormValues((prevFormValues: any) => ({
                              ...prevFormValues,
                              employeeType: value,
                            }));
                          }} showSearch value={formValues?.employeeType} filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {dropDownList?.employeeTypesData?.data?.data?.map((option: any) => (
                              <Select.Option key={option.employeeTypeId} value={option.employeeTypeId}>
                                {option.typeofEmployment}
                              </Select.Option>
                            ))}
                          </Select>
                        </FloatLabel>
                        <ErrorMessage name="employeeType" component="div" className="error-message" />

                      </div>
                    </div>
                    <div className="flex mt-20 mb-8  justify-end w-full ">
                      <Button type="primary" shape="round" className='addButton w-[200px]   _deprovision mr-4' onClick={() => onCancel()} >
                        Cancel
                      </Button>
                      <button className='addButton w-[200px] deprovision text-[#d23b7d]' type='submit'>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </Form>)}
          </div>
        )
      }}
    </Formik>
  )
}
