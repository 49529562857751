import { useSelector, useDispatch } from "react-redux";
import { fetchApiCall } from "../../../../redux/slices/announcementIdSlice";
import { useGetApiRequests, triggerNotification } from '../../../../common/CommonExport';
import { Tooltip } from "antd";

interface Props {
  data: {
    emailAnnouncementId: string;
    isStarred: boolean;
  };
  loader: boolean;
  setLoader: (value: boolean) => void;
}

interface AnnouncementSlice {
  isReload: boolean;
}

export default function Starred({ data, setLoader }: Props) {
  const dispatch = useDispatch();
  const announcementSlice = useSelector((state: { announcementIdSlice: AnnouncementSlice }) => state.announcementIdSlice);
  const isReload = announcementSlice.isReload;
  const setStarred = useGetApiRequests("setStarred", "PUT");

  const handleStarredProcess = (starred: boolean) => {
    setLoader(true)

    const queryParams: any = {
      isStarred: !starred,
    };
    const pathParams = {
      id: data.emailAnnouncementId,
    };
    setStarred("", queryParams, pathParams)
      .then((res: any) => {
        const responseStatus = res?.status
        if (responseStatus === 200) {
          setTimeout(() => {
            dispatch(fetchApiCall(!isReload));
          }, 1000)
        }
      })
      .catch((err) => {
        triggerNotification("error", "", err?.response?.data?.message, "topRight");
        setLoader(false)
      })
  };
  return (
    <div>
      <Tooltip title={`${data?.isStarred ? 'Starred' : 'Not starred'}`}>
        <div
          className={`star-icon cursor-pointer ${data?.isStarred ? "filled" : ""}`}
          onClick={() => handleStarredProcess(data?.isStarred)}
        >
          <span className="icon cursor-pointer">&#9733;</span> 
        </div>
      </Tooltip>
    </div>
  );
}
