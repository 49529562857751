import React, { useState, useEffect } from 'react'
import { Checkbox, Input, Radio } from 'antd'
import { leaveBalance, leaveCount } from './LeavePolicyForms/LeavePolicyConstants';
import { ErrorMessage } from 'formik';


export default function AddLeavePolicyRestriction({ values, setFieldValue, leaveList, isEdit }: any) {
  const [leaveListNew, setLeaveListNew] = useState(leaveList);
  const [weekendsDontCountAsLeave, setWeekendsDontCountAsLeave] = useState(values?.weekendsDontCountAsLeave)
  const [holidaysDontCountAsLeave, setHolidaysDontCountAsLeave] = useState(values?.holidaysDontCountAsLeave)
  const [exceedLeaveBalance, setExceedLeaveBalance] = useState(values?.exceedLeaveBalance)
  useEffect(() => {
    if (isEdit) {
      const selectedKeys = values?.incompatibleLeaveTypes?.split(",")?.map((key: string) => parseInt(key));

      const updatedLeaveList = leaveList?.map((leave: { key: string }) => ({
        ...leave,
        checked: selectedKeys?.includes(leave.key),
      }));
      setLeaveListNew(updatedLeaveList)
    } else {
      setWeekendsDontCountAsLeave('')
      setHolidaysDontCountAsLeave('')
      setExceedLeaveBalance('')
      setFieldValue('weekendsDontCountAsLeave', '')
      setFieldValue('holidaysDontCountAsLeave', '')
      setFieldValue('exceedLeaveBalance', '')
    }
  }, [isEdit])
  const handleRadioButtons = (e: any, type: any) => {
    if (type === 'weekendsDontCountAsLeave') {
      setWeekendsDontCountAsLeave(e.target.value)
      setFieldValue('weekendsDontCountAsLeave', e.target.value)
    } else if (type === 'holidaysDontCountAsLeave') {
      setHolidaysDontCountAsLeave(e.target.value)
      setFieldValue('holidaysDontCountAsLeave', e.target.value)
    } else if (type === 'exceedLeaveBalance') {
      setExceedLeaveBalance(e.target.value)
      setFieldValue('exceedLeaveBalance', e.target.value)
    }
  }

  const handleLeaveList = (label: any, index: any, setFieldValue: any) => {
    const updatedList = leaveListNew.map((item: any, ind: any) =>
      ind === index ? { ...item, checked: !item.checked } : item
    );

    const selectedLabels = updatedList.filter((item: any) => item.checked).map((item: any) => item.key);
    const keysString = selectedLabels.join(',');
    setLeaveListNew(updatedList);
    setFieldValue('incompatibleLeaveTypes', keysString)
  };

  return (
    <div className='restrictionContent mt-4'>
      <div>
        <p className='text-[#484848] font-medium text-[18px]'>Weekends between Leave Periods:</p>
        <Radio.Group name="radiogroup flex" value={weekendsDontCountAsLeave} options={leaveCount} onChange={(e: any) => handleRadioButtons(e, 'weekendsDontCountAsLeave')}></Radio.Group>
      </div>
      <div>
        <p className='text-[#484848] font-medium text-[18px]'>Holidays between leave periods:</p>
        <Radio.Group name="radiogroup flex" value={holidaysDontCountAsLeave} options={leaveCount} onChange={(e: any) => handleRadioButtons(e, 'holidaysDontCountAsLeave')}></Radio.Group>
      </div>
      <div>
        <p className='text-[#484848] font-medium text-[18px]'>While applying for  leave, exceed leave balance :</p>
        <Radio.Group name="radiogroup flex" value={exceedLeaveBalance} options={leaveBalance} onChange={(e: any) => handleRadioButtons(e, 'exceedLeaveBalance')}></Radio.Group>
      </div>
      <div>
        <p className='text-[#484848] font-medium text-[18px]'>Hours duration allowed:</p>
        <div className="flex">
          <div className='addpolicyModalForm flex items-center'>
            <p className='mr-3'>Max no. of hours in a day</p>
            <Input name='maxHrPerDay' value={values?.maxHrPerDay} className=' w-24' placeholder="0" onChange={(e: any) => { setFieldValue('maxHrPerDay', e.target.value) }} />
            <ErrorMessage name="maxHrPerDay" component="div" className="error-message" />
          </div>
          <div className='addpolicyModalForm flex items-center'>
            <p className='mr-3 ml-5'>Max no. of hours in a Month</p>
            <Input name='maxHrPerMonth' value={values?.maxHrPerMonth} className=' w-24' placeholder="0" onChange={(e: any) => { setFieldValue('maxHrPerMonth', e.target.value) }} />
          </div>
        </div>
      </div>
      <div>
        <p className='text-[#484848] font-medium text-[18px]'>Allow request for:</p>
        <Radio name='allowFutureDates' checked={values.allowFutureDates} value={values.allowFutureDates} onChange={(e: any) => { setFieldValue('allowFutureDates', e.target.value) }}>Future dates</Radio>
        <div className="flex py-5">
          <span className='mr-3'>Next</span>
          <Input name='nextDaysAllowed' className=' w-24' placeholder="0" value={values?.nextDaysAllowed} onChange={(e: any) => { setFieldValue('nextDaysAllowed', e.target.value) }} />
          <span className='ml-3'>days</span>
        </div>
        <div className="flex">
          <span className='mr-3'>To be applied</span>
          <Input name='minimumDaysInAdvance' className=' w-24' placeholder="0" value={values?.minimumDaysInAdvance} onChange={(e: any) => { setFieldValue('minimumDaysInAdvance', e.target.value) }} />
          <span className='ml-3'>days in advance</span>
        </div>
      </div>
      <div className="flex pt-5 pb-8">
        <span className='mr-3'>Maximum number of consecutive days of leaves allowed</span>
        <Input name='maximumConsecutiveLeaveDays' className=' w-24' placeholder="0" value={values?.maximumConsecutiveLeaveDays} onChange={(e: any) => { setFieldValue('maximumConsecutiveLeaveDays', e.target.value) }} />
      </div>
      <div className="flex flex-col">
        <span>The leave can't be taken along with </span>
        <br />
        <div className="flex flex-wrap leave-types">
          {leaveListNew.map((label: any, index: any) => (
            <Checkbox name='incompatibleLeaveTypes' checked={label?.checked} onChange={(e) => handleLeaveList(label, index, setFieldValue)} key={label.key} >{label.leavePolicy}</Checkbox>
          ))}
        </div>
      </div>
    </div >
  )
}
