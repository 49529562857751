import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface announcementState {
    announcementList: {}
    isActiveListening: boolean
}

const initialState: announcementState = {
    announcementList: {},
    isActiveListening: false,

};

const announcementSlice = createSlice({
    name: 'announcement',
    initialState,
    reducers: {
        setAnnouncementlist: (state, action: PayloadAction<{}>) => {
            state.announcementList = action.payload;
        },
        clearAnnouncementlist: state => {
            state.announcementList = {};
        },
        setIsActiveListening: (state, action: PayloadAction<boolean>) => {
            state.isActiveListening = action.payload;
        }
    },
});

export const { setAnnouncementlist, clearAnnouncementlist, setIsActiveListening } = announcementSlice.actions;
export default announcementSlice.reducer;
