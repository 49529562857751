import { Tooltip } from 'antd';
import React from 'react';
import { EditorState } from 'draft-js'; // Assuming you're using Draft.js, adjust as necessary

// Define the prop types for the component
interface EditorToolbarProps {
  editorState: EditorState; // Proper type for Draft.js editorState (or replace with your custom type)
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>; // Proper type for setState function
  openCc: boolean;
  onUndo: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onRedo: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onBoldClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onItalicClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onUnderlineClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onBulletedListClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
  onNumberedListClick: (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => void;
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({
  editorState,
  setEditorState,
  openCc,
  onUndo,
  onRedo,
  onBoldClick,
  onItalicClick,
  onUnderlineClick,
  onBulletedListClick,
  onNumberedListClick,
}) => {
  return (
    <div className={`absolute shadow-lg ${openCc ? 'bottom-[12%]' : 'bottom-[14%]'}`}>
      <div className="flex items-center">
        <Tooltip title="Undo">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2"
            onClick={() => onUndo(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">undo</span>
          </button>
        </Tooltip>
        <Tooltip title="Redo">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2"
            onClick={() => onRedo(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">redo</span>
          </button>
        </Tooltip>
        <Tooltip title="Bold">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-[6px] pt-2"
            onClick={() => onBoldClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_bold</span>
          </button>
        </Tooltip>
        <Tooltip title="Italic">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2"
            onClick={() => onItalicClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_italic</span>
          </button>
        </Tooltip>
        <Tooltip title="Underline">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2"
            onClick={() => onUnderlineClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_underlined</span>
          </button>
        </Tooltip>
        <Tooltip title="Bulleted list">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2"
            onClick={() => onBulletedListClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_list_bulleted</span>
          </button>
        </Tooltip>
        <Tooltip title="Numbered list">
          <button
            type="button"
            className="cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2"
            onClick={() => onNumberedListClick(editorState, setEditorState)}
          >
            <span className="material-symbols-outlined">format_list_numbered</span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default EditorToolbar;
