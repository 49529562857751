import React from 'react';
import { ErrorMessage, Field } from 'formik';
import FloatLabel from '../../components/FloatLabel';
import { DatePicker, Input, Select } from 'antd';
import { bloodGroup, genderOptions, martialStatus } from '../Organization/TableColumn';
import RadioButton from '../../components/RadioButton/RadioButton';
import dayjs from 'dayjs';

const EmployeeInformation = ({ values, setFieldValue, handleMarriedStatus, setFieldTouched }: any) => (
  <div className="grid grid-cols-3 gap-4">
    <div className='form-item basicForm-fields mb-12'>
      <p>First Name</p>
      <FloatLabel label='Enter the first name*' name='employeeDto.firstName' value={values.employeeDto.firstName}>
        <Input
          type="text"
          className='float-control'
          name="employeeDto.firstName"
          value={values.employeeDto.firstName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.firstName', false);
            else setFieldTouched('employeeDto.firstName', true)
            setFieldValue('employeeDto.firstName', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.firstName" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Last Name</p>
      <FloatLabel label='Enter the last name*' name='employeeDto.lastName' value={values.employeeDto.lastName}>
        <Input
          type="text"
          className='float-control'
          name="employeeDto.lastName"
          value={values.employeeDto.lastName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.lastName', false);
            else setFieldTouched('employeeDto.lastName', true)
            setFieldValue('employeeDto.lastName', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.lastName" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12'>
      <p>Date of Birth</p>
      <FloatLabel label='Select the date of birth*' name='employeeDto.dateOfBirth' value={values.employeeDto.dateOfBirth ? dayjs(values.employeeDto.dateOfBirth) : null}>
        <DatePicker
          placeholder=''
          className='float-control'
          name="employeeDto.dateOfBirth"
          value={values.employeeDto.dateOfBirth ? dayjs(values.employeeDto.dateOfBirth) : null}
          onChange={(date, dateString) => setFieldValue('employeeDto.dateOfBirth', dateString)}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.dateOfBirth" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12'>
      <p>Gender</p>
      <Field
        name="employeeDto.gender"
        render={({ field, form }: any) => (
          <RadioButton
            {...field}
            options={genderOptions}
            onChange={(newValue: any) => {
              form.setFieldValue(field.name, newValue);
            }}
          />
        )}
      />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Blood Group</p>
      <FloatLabel label='Select the blood group*' name='employeeDto.bloodGroup' value={values.employeeDto.bloodGroup}>
        <Select
          className='float-control' value={values.employeeDto.bloodGroup}
          onChange={(value) => setFieldValue('employeeDto.bloodGroup', value)}>
          {bloodGroup?.map((item: any) => (
            <Select.Option key={item?.value} value={item?.value}>
              {item?.text}
            </Select.Option>
          ))}
        </Select>
      </FloatLabel>
      <ErrorMessage name="employeeDto.bloodGroup" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Martial Status</p>
      <Field
        name="employeeDto.isMarried"
        render={({ field, form }: any) => (
          <RadioButton
            {...field}
            options={martialStatus}
            onChange={(newValue: any) => {
              form.setFieldValue(field.name, newValue);
              handleMarriedStatus(newValue, values)
            }}
          />
        )}
      />
    </div>
    {values.employeeDto.isMarried && (
      <div className='form-item basicForm-fields mb-12' >
        <p>Spouse's Name</p>
        <FloatLabel label='Enter the spouse name*' name='employeeDto.fatherOrSpouseName' value={values.employeeDto.fatherOrSpouseName}>
          <Input
            type="text"
            className='float-control'
            name="employeeDto.fatherOrSpouseName"
            value={values.employeeDto.fatherOrSpouseName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.fatherOrSpouseName', false);
              else setFieldTouched('employeeDto.fatherOrSpouseName', true)
              setFieldValue('employeeDto.fatherOrSpouseName', e.target.value)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeDto.fatherOrSpouseName" component="div" className="error-message" />
      </div>
    )}
    <div className='form-item basicForm-fields mb-12' >
      <p>Father's Name</p>
      <FloatLabel label={`Enter the father's name*`} name='employeeDto.fatherName' value={values.employeeDto.fatherName}>
        <Input
          type="text"
          className='float-control'
          name="employeeDto.fatherName"
          value={values.employeeDto.fatherName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.fatherName', false);
            else setFieldTouched('employeeDto.fatherName', true)
            setFieldValue('employeeDto.fatherName', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.fatherName" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Mother's Name</p>
      <FloatLabel label={`Enter the mother's name`} name='employeeDto.motherName' value={values.employeeDto.motherName}>
        <Input
          type="text"
          className='float-control'
          name="employeeDto.motherName"
          value={values.employeeDto.motherName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.motherName', false);
            else setFieldTouched('employeeDto.motherName', true)
            setFieldValue('employeeDto.motherName', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.motherName" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Contact Number</p>
      <FloatLabel label='Enter the contact number*' name='employeeDto.personalContactNo' value={values.employeeDto.personalContactNo}>
        <Input
          maxLength={10}
          type="text"
          className='float-control'
          name="employeeDto.personalContactNo"
          value={values.employeeDto.personalContactNo}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.personalContactNo', false);
            else setFieldTouched('employeeDto.personalContactNo', true)
            setFieldValue('employeeDto.personalContactNo', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.personalContactNo" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Personal Email Id</p>
      <FloatLabel label='Enter the personal email id*' name='employeeDto.personalEmail' value={values.employeeDto.personalEmail}>
        <Input
          type="text"
          className='float-control'
          name="employeeDto.personalEmail"
          value={values.employeeDto.personalEmail}
          onChange={(e) => setFieldValue('employeeDto.personalEmail', e.target.value)}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.personalEmail" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>Aadhar Number</p>
      <FloatLabel label='Enter the Aadhar number*' name='employeeDto.aadharNumber' value={values.employeeDto.aadharNumber}>
        <Input
          maxLength={12}
          type="text"
          className='float-control'
          name="employeeDto.aadharNumber"
          value={values.employeeDto.aadharNumber}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.aadharNumber', false);
            else setFieldTouched('employeeDto.aadharNumber', true)
            setFieldValue('employeeDto.aadharNumber', e.target.value)
          }}
          onBlur={(e) => {
            if (/^\d{12}$/.test(e.target.value))
              setFieldTouched('employeeDto.aadharNumber', false);
            else setFieldTouched('employeeDto.aadharNumber', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.aadharNumber" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>PAN</p>
      <FloatLabel label='Enter the PAN*' name='employeeDto.panNumber' value={values.employeeDto.panNumber}>
        <Input
          maxLength={10}
          type="text"
          className='float-control'
          name="employeeDto.panNumber"
          value={values.employeeDto.panNumber}
          onChange={(e) => {
            const inputValue = e.target.value;
            const checkValidate = () => {
              if (/^[A-Za-z ]+$/.test(inputValue)) {
                setFieldTouched('employeeDto.panNumber', false);
              } else setFieldTouched('employeeDto.panNumber', true)
            }
            if (inputValue?.length <= 5) checkValidate()
            else if (inputValue?.length >= 6 && inputValue?.length <= 9) {
              const onlyNumeric = inputValue.substring(5, 9);
              if (/^\d+$/.test(onlyNumeric)) {
                setFieldTouched('employeeDto.panNumber', false);
              } else setFieldTouched('employeeDto.panNumber', true)
            } else if (inputValue?.length >= 10 && inputValue?.length === 10) checkValidate()
            setFieldValue('employeeDto.panNumber', e.target.value.toUpperCase())
          }}
          onBlur={(e) => {
            if (e.target.value?.length === 10)
              setFieldTouched('employeeDto.panNumber', false);
            else setFieldTouched('employeeDto.panNumber', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.panNumber" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>UAN</p>
      <FloatLabel label='Enter the UAN' name='employeeDto.uanNumber' value={values.employeeDto.uanNumber}>
        <Input
          maxLength={12}
          type="text"
          className='float-control'
          name="employeeDto.uanNumber"
          value={values.employeeDto.uanNumber}
          onChange={(e) => setFieldValue('employeeDto.uanNumber', e.target.value.toUpperCase())}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.uanNumber" component="div" className="error-message" />
    </div>
    <div className='form-item basicForm-fields mb-12' >
      <p>ESI Number</p>
      <FloatLabel label='Enter the ESI number' name='employeeDto.esiNumber' value={values.employeeDto.esiNumber}>
        <Input
          maxLength={17}
          type="text"
          className='float-control'
          name="employeeDto.esiNumber"
          value={values.employeeDto.esiNumber}
          onChange={(e) => setFieldValue('employeeDto.esiNumber', e.target.value.toUpperCase())}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.esiNumber" component="div" className="error-message" />
    </div>
  </div>
);
export default EmployeeInformation;