// slices/formSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

interface FormState {
    toEmails: string[];
    subjects: string;
    selectedDate: string | null; // Set the type to string | null
}

const initialState: FormState = {
    toEmails: [],
    subjects: '',
    selectedDate: null,
};

const announcementFilterFormSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setFormData: (state, action: PayloadAction<FormState>) => {
            const { toEmails, subjects, selectedDate } = action.payload;
            state.toEmails = toEmails;
            state.subjects = subjects;
            state.selectedDate = selectedDate;
        },
        clearFormData: (state) => {
            state.toEmails = [];
            state.subjects = '';
            state.selectedDate = null;
        },
    },
});

export const { setFormData, clearFormData } = announcementFilterFormSlice.actions;
export default announcementFilterFormSlice.reducer;
