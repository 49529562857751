
import React, { createContext, useEffect, useState } from 'react'
import { DatePicker, Modal, Select, Switch, Input } from 'antd'
import FloatLabel from '../../../../../../components/FloatLabel';
import AppraisalAssesment from './AppraisalAssesment';
import { Yup, storeData, triggerNotification, useGetApiRequests, useSelector } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import dayjs from 'dayjs';
import Loader from '../../../../../../components/Loader/Loader';
import CloseModalForm from './EmailAppraisal/CloseModalForm';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
export interface AppContextData {
  questionsArray: any;
  appraiserFeedback: any[];
  functionalAttributes: any[];
  keyPerformance: any[];
  organisationAttributes?: any[];
  personalAttributes?: any[];
}
export const EmployeeAppraisalContext = createContext<AppContextData>({} as AppContextData);

const AppraisalReviewFoem = (props: any) => {
  const { open, handleCloseModal, getReviewsApi, isView, employeeReviewId, isPending } = props;
  const { id }: any = useParams();
  const dateFormat = 'YYYY-MM-DD';
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const getQuestions = useGetApiRequests('getquestions', 'GET');
  const getReviewDetails = useGetApiRequests('getReviewDetails', 'GET')
  const [loader, setLoader] = useState(false);
  const [dropdownList, setDropdownList] = useState<any>([]);
  const [appraiserFeedback, setAppraiserFeedback] = useState([]);
  const [functionalAttributes, setFunctionalAttributes] = useState([]);
  const [organisationAttributes, setOrganisationAttributes] = useState([]);
  const [personalAttributes, setPersonalAttributes] = useState([]);
  const [date, setDate] = useState('');
  const [reviewtype, setReviewtype] = useState("")
  const [keyPerformance, setKeyPerformance] = useState([]);
  const [itsIntern, setItsIntern] = useState<any>(null);
  const [type, setType] = useState('');
  const [salaryDate, setSalaryDate] = useState('');
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const [probationEndDate, setProbationEndDate] = useState('')
  const [questionsArray, setQuestionsArray] = useState({
    keyperformance: [], personalattributes: [], functionalattributes: [], organisationalattributes: [],
    appraiserfeedback: { significantachievements: null, improvementareas: null, technicalskills: null, softskills: null, appraisercomments: null }
  })
  const [isEligible, setIsEligible] = useState(false)

  const [initialValues, setInitialValues] = useState<any>({
    reviewtype: '',
    salaryRevised: '',
    incrementEligible: false,
    probationExtension: ''
  })
  const [ctcLables, setCtcLables] = useState<any>({
    currentCtc: 0,
    newCtc: 0,
    comments: ''
  })
  const validationSchema = Yup.object().shape({
    reviewtype: Yup.string().required('Review Type is required'),
    salaryRevised: Yup.string().test(
      'require-salaryRevised',
      'Salary Revised Date is required',
      function (value) {
        const incrementEligible = this.parent?.incrementEligible;
        if (
          (incrementEligible === true) &&
          !value
        ) {
          return this.createError({
            path: this.path,
            message: 'Salary Revised Date is required',
          });
        }
        return true;
      }
    ),
    probationExtension: Yup.string().test(
      'require-probationExtension',
      'Probation Extension date is required',
      function (value) {
        const reviewtype: any = this.parent.reviewtype;

        if (reviewtype === 'Probation Extension' && !value) {
          return this.createError({
            path: this.path,
            message: 'Probation Extension date is required',
          });
        }
        return true;
      }
    ),
  });
  const dateConversion = (value: any) => {
    const apiDate = new Date(value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const getReviewsDetails = async () => {
    const pathParams: any = {
      id: employeeReviewId,
      end: 'detail'
    }
    setLoader(true);
    await getReviewDetails('', '', pathParams)
      .then((response: any) => {
        const apiRes: any = response.data?.data?.employeeReviewDetails;

        if (apiRes !== null) {
          setAppraiserFeedback(apiRes.APPRAISER_FEEDBACK);
          setFunctionalAttributes(apiRes.FUNCTIONAL_ATTRIBUTES);
          setKeyPerformance(apiRes.KEY_PERFORMANCE);
          setOrganisationAttributes(apiRes.ORGANISATIONAL_ATTRIBUTES);
          setPersonalAttributes(apiRes.PERSONAL_ATTRIBUTES);
          setIsEligible(response.data?.data?.isEligibleForIncrement)
          if (response?.data?.data?.isEligibleForIncrement === true) {
            setSalaryDate(dateConversion(response?.data?.data?.newCtcEffectiveDate))
          }
          setInitialValues({ reviewtype: response.data?.data.type, incrementEligible: apiRes?.isEligibleForIncrement })
          setReviewtype(response.data?.data.type !== '' && response.data?.data.type !== null && response.data.data.type === 'Probation Extension' ? '31' : response.data.data.type === 'Confirmed' ? '15' : response.data.data.type)
          setCtcLables({
            currentCtc: response?.data?.data.currentCtc,
            newCtc: response?.data?.data.newCtc,
            comments: response?.data?.data.remarks
          })
          setType(response?.data?.data?.type);
          setProbationEndDate(dateConversion(response?.data?.data?.nextProbationEndDate))
        } else {
          const reviewTypeFromApi: any = response.data?.data.type !== '' && response.data?.data.type !== null && response.data.data.type === 'Probation Extension' ? '31' : response.data.data.type === 'Confirmed' ? '15' : response.data.data.type;
          setReviewtype(reviewTypeFromApi)
          storeData("reviewtype", reviewTypeFromApi, true)
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.message, 'topRight');
      });
  };

  const getCurrentDate = () => {
    const now = dayjs();
    const currentdate = now.format('YYYY-MM-DD');
    setDate(currentdate);
    storeData("salaryRevised", currentdate, true)
  }
  useEffect(() => {
    if (!isView) {
      getQuestionsApi();
      getCurrentDate()
    }
    else if (isView) {
      getReviewsDetails();
      getQuestionsApi();
      getCurrentDate()
    }
  }, [open])

  const onChangeDate = (date: any, dateString: any, setFieldValue: any): any => {
    setDate(dateString)
    setFieldValue('salaryRevised', dateString);
    storeData("salaryRevised", dateString, true)

  };

  const getQuestionsApi = async () => {
    const params: any = {
      designationId: designationId, organisationId: orgId
    };
    const pathParams: any = {
      id: id
    }
    setLoader(true);
    await getQuestions('', params, pathParams)
      .then((response: any) => {
        if (isView && !isPending) {
          const temDropDownArray: any = [];
          if (response?.data?.data?.reviewType !== null) {
            setItsIntern(false);
            response?.data?.data.reviewType.map((item: any) => {
              const tempDropDown: any = {
                value: item,
                label: item
              }
              temDropDownArray.push(tempDropDown)
              setDropdownList(temDropDownArray)
            });
          } else {
            setItsIntern(true);
            const dropdownOptions = Object.entries(response?.data?.data?.status).map(([value, label]) => ({
              value: value,
              label,
            }));
            setDropdownList(dropdownOptions)
          }
        } else {
          let tempQuestionList: any = questionsArray;
          let questionKeys: any = Object.keys(response?.data?.data?.questions);
          questionKeys.forEach((item: any) => {
            let editedKey = item.split("_").join("").toLowerCase();
            if (editedKey === "appraiserfeedback") {
              response?.data?.data?.questions &&
                response?.data?.data?.questions[item].forEach((data: any) => {
                  let tempData = data;
                  let questionKey = data.question.split(" ").join("").toLowerCase();
                  tempData["answer"] = "";
                  tempData["range"] = null;
                  tempQuestionList[editedKey][questionKey] = tempData;
                });
            } else {
              response?.data?.data?.questions &&
                response?.data?.data?.questions[item].forEach((data: any) => {
                  let tempData = data;
                  tempData["answer"] = "";
                  tempData["range"] = null;
                  tempQuestionList[editedKey].push(tempData);
                });
            }
          });
          setQuestionsArray(tempQuestionList);
          const apiResponse = response?.data?.data?.questions;
          setAppraiserFeedback(apiResponse?.APPRAISER_FEEDBACK);
          setFunctionalAttributes(apiResponse?.FUNCTIONAL_ATTRIBUTES);
          setKeyPerformance(apiResponse?.KEY_PERFORMANCE);
          setOrganisationAttributes(apiResponse?.ORGANISATIONAL_ATTRIBUTES);
          setPersonalAttributes(apiResponse?.PERSONAL_ATTRIBUTES);
          setLoader(false);
          const temDropDownArray: any = [];
          if (response?.data?.data?.reviewType !== null) {
            setItsIntern(false);
            response?.data?.data.reviewType.map((item: any) => {
              const tempDropDown: any = {
                value: item,
                label: item
              }
              temDropDownArray.push(tempDropDown)

              setDropdownList(temDropDownArray)
            });
          } else {
            setItsIntern(true);
            const dropdownOptions = Object.entries(response?.data?.data?.status).map(([value, label]) => ({
              value: value,
              label
            }));
            setDropdownList(dropdownOptions)
          }
        }

      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const handleClose = () => {
    if (isView === true && isPending === false) {
      handleCloseModal();
    } else {
      setCloseConfirmationModal(true)
    }
  };
  function doNothing() {
    // doNothing function
  };
  const onSaveButtonClick = () => {
    doNothing();
  }
  const handleReviewType = (value: any, setFieldValue: any) => {
    setFieldValue('reviewtype', value)
    setReviewtype(value);
    storeData("reviewtype", value, true)
    const reviewObject: any = dropdownList.find((item: any) => item.value === value)
    storeData("reviewObject", reviewObject, true)
  }
  const handleEligible = (setFieldValue: any, values: any) => {
    setFieldValue('incrementEligible', !values.incrementEligible);
    setIsEligible(!isEligible)
  }
  return (
    <Modal title='Appraisal ' className='salary-modal' centered open={open} onCancel={handleClose} footer={null} maskClosable={false}  >
      <div className="salary-date review-modal">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSaveButtonClick}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className='mr-1'>
                <p><span className='general-assessment-header'>Note:</span> All fields are mandatory*</p>
                <div className='grid grid-cols-3 gap-x-[7.5rem] gap-y-4 mt-4 w-full form-item'>
                  {!isView || (isView && isPending) ? (
                    <Select
                      placeholder={'Status Type*'} options={dropdownList}
                      onChange={(value) => { handleReviewType(value, setFieldValue) }}
                      value={dropdownList.filter((item: any) => item.value === reviewtype)}
                      disabled={!!(isView === true && isPending === false)}
                    ></Select>) : (
                    <label>Status Type*
                      <Input value={type} disabled={isView} />
                    </label>)}
                  {!itsIntern && itsIntern !== null && (
                    <div className="review-toggle flex items-center justify-end">
                      <label htmlFor="incrementEligible" className='pr-2 pt-0'>
                        Eligible for Increment?
                      </label>
                      <Field
                        type="checkbox"
                        name="incrementEligible"
                        as={Switch}
                        checked={isEligible}
                        onChange={(event: any) => handleEligible(setFieldValue, values)}
                        disabled={!!(isView === true && isPending === false)}
                      />
                    </div>)}
                  {isEligible === true && (
                    <div className="revised-date">
                      <FloatLabel label='Salary Revised On*' name='salaryRevised'>
                        <DatePicker
                          className='float-control'
                          name="salaryRevised"
                          onChange={(date, dateString) => onChangeDate(date, dateString, setFieldValue)}
                          defaultValue={!isView ? dayjs(date, dateFormat) : (isView && isPending) ? dayjs(date, dateFormat) : dayjs(salaryDate, dateFormat)} format="YYYY-MM-DD"
                          disabled={!!(isView === true && isPending === false)}
                        />
                      </FloatLabel>
                    </div>)}
                </div>
                {(!isView && values.reviewtype === "31") && (
                  <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5'>
                    <FloatLabel label='Probation Extension Date*' name='probationExtension' className="w-11">
                      <DatePicker
                        className='float-control'
                        name="probationExtension"
                        onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                        defaultValue={!isView ? dayjs(date, dateFormat) : dayjs(probationEndDate, dateFormat)} format="YYYY-MM-DD"
                        disabled={!!(isView === true && isPending === false)}
                      />
                    </FloatLabel>
                  </div>)}
                {(isView && values.reviewtype === "31") && (
                  <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5'>
                    <FloatLabel label='Probation Extension Date*' name='probationExtension' className="w-11">
                      <DatePicker
                        className='float-control'
                        name="probationExtension"
                        onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                        defaultValue={dayjs(date, dateFormat)} format="YYYY-MM-DD"
                        disabled={!!(isView === true && isPending === false)}
                      />
                    </FloatLabel>
                  </div>)}
                {isView && type === "Probation Extension" && type !== null && (
                  <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5'>
                    <FloatLabel label='Probation Extension Date*' name='probationExtension' className="w-11">
                      <DatePicker
                        className='float-control'
                        name="probationExtension"
                        onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                        defaultValue={dayjs(probationEndDate, dateFormat)} format="YYYY-MM-DD"
                        disabled={!!(isView === true && isPending === false)}
                      />
                    </FloatLabel>
                  </div>)}
                <div>
                  <AppraisalAssesment employeeReviewId={employeeReviewId} ctcDetails={ctcLables} nextButton={isEligible} isView={isView} handleCloseModal={handleCloseModal} questionsArray={questionsArray} appraiserFeedback={appraiserFeedback} functionalAttributes={functionalAttributes} keyPerformance={keyPerformance} organisationAttributes={organisationAttributes} personalAttributes={personalAttributes} reviewType={values.reviewtype ?? reviewtype} salaryRevised={values.salaryRevised !== '' ? values.salaryRevised : date} probationExtension={values.probationExtension !== '' ? values.probationExtension : date} incrementEligible={values.incrementEligible} getReviewsApi={getReviewsApi} isPending={isPending} />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {loader && <ModalLoader />}
      {closeConfirmationModal && < CloseModalForm handleCloseModal={handleCloseModal} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} />}

    </Modal >
  )
}
export default AppraisalReviewFoem
