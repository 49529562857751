import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    id: string | null;
}

const initialState: AuthState = {
    id: null,
};

const orgSlice = createSlice({
    name: 'organisationId',
    initialState,
    reducers: {
        setOrgId: (state, action: PayloadAction<string | null>) => {
            state.id = action.payload;
        },
        clearOrgId: state => {
            state.id = null;
        },
    },
});

export const { setOrgId, clearOrgId } = orgSlice.actions;
export default orgSlice.reducer;
