import React, { useRef, useState } from 'react';
import ScheduleDateModal from './modal/ScheduleDateModal';
import EditLinkModal from './modal/EditLinkModal';

// import EmojiSuggestions from '@draft-js-plugins/emoji'; // Default import
// import EmojiSelect from '@draft-js-plugins/emoji'; // Default import

interface ComposeMailToobarProps {
  handleIconModal?: () => void;
  handleFileSelect?: () => void;
  onAddLink?: () => void;
  onAddEmoji?: () => void;
  onAddPhoto?: () => void;
  onSchedule?: () => void;
  formattedScheduleDate?: string | null;
  formattedScheduleTime?: string | null;
  iconModal?: boolean | null;
  openLinkModal?: () => void;
}

const ComposeMailToobar: React.FC<ComposeMailToobarProps> = ({
  handleIconModal,
  handleFileSelect,
  // onAddLink,
  // onAddEmoji,
  // onAddPhoto,
  // onSchedule,
  formattedScheduleDate,
  formattedScheduleTime,
  iconModal,
  openLinkModal
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [scheduleModal, setScheduleModal] = useState(false)
  const [editLink, setEditLink] = useState(false)
  const handleIcon = () => {
    if (handleIconModal) {
      handleIconModal();
    } else {

    }
  };
  const triggerFileControl = () => {
    if (handleFileSelect) {
      handleFileSelect();
    } else {

    }
  };

  return (
    <div className='flex items-center justify-center gap-[3px]'>
      <div>
        <button
          type="button"
          className={`cursor-pointer text-[#757575] hover:bg-[#D34A7C24] ${iconModal ? 'bg-[#D34A7C24]' : ''} rounded-md p-1 px-2 pt-2`}
          onClick={handleIcon}>
          <span className="material-symbols-outlined">text_format</span>
        </button>
      </div>
      <div>
        <button
          type="button"
          className='cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2'
          onClick={triggerFileControl}>
          <span className="material-symbols-outlined">attach_file</span>
        </button>
      </div>
      <div>
        <button
          type="button"
          className='cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2'
          onClick={openLinkModal}>
          <span className="material-symbols-outlined">link</span>
        </button>
      </div>
      <div className=' compose-emoji rounded-md  p-1'>
        {/* <button
          type="button"
          className='cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md  p-1'
          onClick={onAddEmoji || (() => console.log('Emoji picker triggered'))}>
          <span className="material-symbols-outlined">mood</span>
        </button> */}

      </div>
      {/* <div>
        <button
          type="button"
          className='cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2'
          onClick={onAddPhoto || (() => console.log('Add photo triggered'))}>
          <span className="material-symbols-outlined">add_photo_alternate</span>
        </button>
      </div> */}
      <div className=' ml-7'>
        <button
          type="button"
          className={`cursor-pointer text-[#757575] hover:bg-[#D34A7C24] rounded-md p-1 px-2 pt-2 ${formattedScheduleDate ? 'bg-[#D34A7C24]' : ''
            }`}
          onClick={() => setScheduleModal(true)}>
          <span className="material-symbols-outlined">calendar_clock</span>
        </button>
      </div>
      {scheduleModal && (
        <ScheduleDateModal
          setScheduleModal={setScheduleModal}
          scheduleModal={scheduleModal}
          formattedScheduleDate={formattedScheduleDate}
          formattedScheduleTime={formattedScheduleTime} />
      )
      }
      {
        editLink && (
          <EditLinkModal
            title='Edit Link'
            setEditLink={setEditLink}
            editLink={editLink} />
        )
      }
    </div>
  );
};

export default ComposeMailToobar;
