import React, { useEffect, useRef, useState } from 'react';
import ImagesComponent from '../../../components/Images/Images';
import birthdayImg from '../../../assets/images/new-dashboard/birthday.svg';
import celebration from '../../../assets/images/new-dashboard/celebration.svg';
import appraisalImg from '../../../assets/images/new-dashboard/appraisal.svg';
import probation from '../../../assets/images/new-dashboard/probation.svg';
import { getCurrentMonth, sortEventData } from '../DashBoardHelper';
import noRecordFound from '../../../assets/images/empty-folder.png'
import apprasialEmp from '../../../assets/images/employee/appraisal.svg'
import birthdayEmp from '../../../assets/images/employee/birthday.svg'
import celebrationEmp from '../../../assets/images/employee/celebration.svg'
import { useNavigate } from 'react-router-dom';
import { setAppraisalTab } from '../../../redux/slices/updateProfileSlice';
import { useDispatch } from 'react-redux';
import EventLoader from '../../../components/Loader/EventLoader';
import { setAnnouncementlist } from '../../../redux/slices/announcementSlice';
import DOMPurify from "dompurify";

// Define interfaces for the object structure
export interface EmailRecipient {
    emailRecipientId: number;
    toEmail: string | null;
    ccEmail: string | null;
    bccEmail: string | null;
}

export interface Announcement {
    emailAnnouncementId: number;
    senderCntUserId: number;
    subject: string;
    body: string;
    status: string;
    scheduledTime: string | null;
    isStarred: boolean;
    attachments: any[]; // Replace `any` with a specific type if known
    emailRecipient: EmailRecipient;
    isActive: boolean;
    createdOn: string;
    organisationId: number;
}
function Announcements(props: any) {

    const { title, announcementList } = props;

    const [currMonth, setCurrMonth] = useState<any>();
    const [loader, setLoader] = useState<any>(true);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        setCurrMonth(getCurrentMonth());
        setTimeout(() => {
            setLoader(false);
        }, 3500);
    }, []);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer) return;
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
            if (scrollTop + clientHeight >= scrollHeight) {
                setScrollDirection('up');
            } else if (scrollTop <= 0) {
                setScrollDirection('down');
            }
        };
        const autoScroll = setInterval(() => {
            if (scrollDirection === 'down') {
                scrollContainer.scrollBy({ top: 1, behavior: 'smooth' });
            } else {
                scrollContainer.scrollBy({ top: -1, behavior: 'smooth' });
            }
            handleScroll();
        }, 30);
        return () => clearInterval(autoScroll);
    }, [scrollDirection]);

    const getImageForEvent = (status: any) => {
        if (status === 'Work Anniversary') return { mainImg: celebration, empImage: celebrationEmp };
        if (status === 'Birthday') return { mainImg: birthdayImg, empImage: birthdayEmp };
        if (status === 'Probation') return { mainImg: probation, empImage: probation };
        if (status === 'Appraisal') return { mainImg: appraisalImg, empImage: apprasialEmp };
    };
    const handleNavigateToAnnouncement = (item: Announcement) => {
        if (item) {
            dispatch(setAnnouncementlist(item));
            navigate('/home/announcements/sent/single-announcements');
        }
    }
    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date).replace(' ', '-');
    };
    function findNearestDate(dates: any, currentDate: any) {
        const currentDateNum = new Date(currentDate).getDate();
        const datesNum = new Date(dates).getDate();
        const difference = datesNum - currentDateNum
        // if (difference === 1 || difference === 2) {
        const element: any = document.getElementById(currentDate);
        element?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        // }
    }
    const scroll = (element: any) => {
        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 2000);
    }
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer) return;
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
            if (scrollTop + clientHeight >= scrollHeight) {
                setScrollDirection('up');
            } else if (scrollTop <= 0) {
                setScrollDirection('down');
            }
        };
        const autoScroll = setInterval(() => {
            if (scrollDirection === 'down') {
                scrollContainer.scrollBy({ top: 1, behavior: 'smooth' });
            } else {
                scrollContainer.scrollBy({ top: -1, behavior: 'smooth' });
            }
            handleScroll();
        }, 30);
        return () => clearInterval(autoScroll);
    }, [scrollDirection]);

    const truncateText = (text: string | null | undefined, maxLength: number): string => {
        if (!text) return '';
        return text?.length > maxLength ? text?.slice(0, maxLength) + "..." : text;
    };

    const renderAnnouncement = (item: any) => {
        const sanitizeAnnouncementContent = (announcementContent: string) => {
            const sanitizedBody = announcementContent ? DOMPurify.sanitize(announcementContent) : '';
            return sanitizedBody;
        }
        const plainTextBody = item && item.body ? item.body : '';
        const truncatedBody = truncateText(plainTextBody, 33);
        return (
            <div className={`birth-anniversary__list-cont items-center cursor-pointer`}
                onClick={() => {
                    handleNavigateToAnnouncement(item)
                }}>
                <div className='birth-anniversary__profile-img flex justify-center items-center'>
                    <span className="material-symbols-outlined text-[#d23b7d]">
                        campaign
                    </span>
                </div>
                <div className=' birth-anniversary__emp-hooray flex cursor-pointer'>
                    <p className='dashboard-announce-body' > {item?.subject}</p>
                    <span className='dashboard-announce-body'>
                        <div dangerouslySetInnerHTML={{ __html: sanitizeAnnouncementContent(truncatedBody) }} />
                    </span>
                </div>

            </div>
        )
    }

    return (
        <div className='birth-anniversary relative'>
            {/* {loader && <EventLoader />} */}
            <div className='birth-anniversary__tab flex justify-between relative top-4'>
                <div className='card-title justify-start'>
                    <h4>{title}</h4>
                </div>
                <div className='expand expand-icon justify-end text-primary'>
                    {/* <span className='material-symbols-outlined text-lg'>open_in_full</span> */}
                </div>
            </div>

            {
                announcementList?.data?.length > 0 ? (
                    <div ref={scrollContainerRef} className=' overflow-y-auto pt-7 mt-4'>
                        <ul className='birth-anniversary__timeline' id="anniversary">
                            {announcementList?.data?.map((item: any, index: number) => {
                                const currDate = new Date(item.updatedOn).getDate();
                                const currMonth = new Date(item.updatedOn).toLocaleString('default', { month: 'short' });

                                // Determine if the previous item's date differs from the current item's date
                                const prevDate =
                                    index > 0
                                        ? new Date(announcementList.data[index - 1]?.updatedOn).getDate()
                                        : null;

                                return (
                                    <li
                                        key={`${item.emailAnnouncementId}+${index}`}
                                        className="birth-anniversary__list relative"
                                        id={`date-${currDate}`}
                                    >
                                        {prevDate !== currDate && (
                                            <div className="date-header">
                                                <span className="birth-anniversary__date absolute items-center flex justify-center">
                                                    {currDate}
                                                </span>
                                                <span className="birth-anniversary__month absolute">
                                                    {currMonth}
                                                </span>
                                            </div>
                                        )}
                                        <div className="announcement-content">
                                            {renderAnnouncement(item)}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    <div className="no-record salary">
                        <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
                        </p>
                    </div>
                )
            }
        </div >
    );
}

export default Announcements;
