import { Modal, TimePicker } from 'antd';
import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { TimePickerProps } from 'antd';
import BigCalender from '../../Dashboard/Announcements/BigCalender';
import { useDispatch, useSelector } from 'react-redux';
import { setScheduleDate, setScheduleTime, resetSchedule } from '../../../redux/slices/announcementScheduleSlice'; // Updated path

dayjs.extend(customParseFormat);

type ScheduleDateModalProps = {
  scheduleModal: boolean;
  setScheduleModal: (value: boolean) => void;
  formattedScheduleDate?: string | null;
  formattedScheduleTime?: string | null;
};

const ScheduleDateModal: React.FC<ScheduleDateModalProps> = ({ scheduleModal, setScheduleModal, formattedScheduleDate, formattedScheduleTime }) => {

  const dispatch = useDispatch();


  // Get the initial date and time from Redux store
  const scheduleDate = useSelector((state: any) => state.AnnouncementScheduleSlice.scheduleDate);
  const scheduleTime = useSelector((state: any) => state.AnnouncementScheduleSlice.scheduleTime);

  // Initialize selectedDate and selectedTime based on the Redux store values
  const [selectedDate, setSelectedDate] = useState<Dayjs>(scheduleDate ? dayjs(scheduleDate) : dayjs());
  const [selectedTime, setSelectedTime] = useState<Dayjs>(scheduleTime ? dayjs(scheduleTime) : dayjs());

  const dateEventListener = (date: any) => {
    console.log('Selected Date:', date);
    setSelectedDate(dayjs(date)); // Convert to a Dayjs object
  };

  const timeEventListener: TimePickerProps['onChange'] = (time, timeString) => {
    console.log('Selected Time:', timeString);
    setSelectedTime(dayjs(time)); // Convert to a Dayjs object
  };

  const handleClose = () => {
    setSelectedDate(dayjs());  // Reset to the current date
    setSelectedTime(dayjs());  // Reset to the current time
    setScheduleModal(false);   // Close the modal
    dispatch(resetSchedule());
  };
  const Schedule = () => {
    // Use current date and time if either selectedDate or selectedTime is null or undefined
    const finalDate = selectedDate || dayjs(); // Fallback to current date if selectedDate is null or undefined
    const finalTime = selectedTime || dayjs(); // Fallback to current time if selectedTime is null or undefined  
    // Dispatch final date and time to Redux store
    dispatch(setScheduleDate(finalDate)); // Dispatch final date
    dispatch(setScheduleTime(finalTime)); // Dispatch final time
    setScheduleModal(false); // Close the modal after scheduling
  };

  return (
    <div className="scheduleDateModal-container">
      <Modal
        title=""
        centered
        open={scheduleModal}
        footer={null}
        className="scheduleDateModal-modal"
        width={630}
        maskClosable={false}
        onCancel={handleClose}
      >
        <div>
          <div className="mb-4">
            <div className="my-2">
              <p className="text-[#434343] font-rubik">
                Select date and time to schedule:{" "}
                {/* <span className=' text-[#d34a7c] font-rubik'>{formattedScheduleDate && formattedScheduleTime ? `${formattedScheduleDate}, ${formattedScheduleTime}` : ""}</span> */}
                <span className="text-[#d34a7c] font-rubik">
                  <span className="text-[#d34a7c] font-rubik">
                    {`${selectedDate.format("DD-MM-YYYY")}  ,  ${selectedTime.format("hh:mm A")}`}
                  </span>
                </span>

              </p>
            </div>
            <div className="mt-3 flex">
              <div className="border-r pr-7">
                <div>
                  <BigCalender
                    readOnly={false}
                    selectedDate={selectedDate || dayjs()} // Handle null by using current date
                    onChange={dateEventListener}
                  />
                </div>
              </div>
              <div className="pl-7">
                <p className="text-[#434343] mb-2 font-normal font-rubik">Time</p>
                <TimePicker
                  format="hh:mm A"
                  use12Hours
                  onChange={timeEventListener}
                  value={selectedTime || dayjs()} // Handle null by using current time
                  defaultOpenValue={dayjs()} // Default to current time
                />
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-end mb-2">
            <button
              className="cursor-pointer text-[#D34A7C] font-rubik bg-[#FFF5F9] border border-[#D23B7D] w-[130px] h-[45px] rounded-3xl text-[18px] mr-4 secondary  hover:opacity-60  hover:transition-opacity"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="cursor-pointer text-white font-rubik bg-[#D23B7D] w-[130px] h-[45px] rounded-3xl text-[18px] mr-4  hover:opacity-60  hover:transition-opacity"
              onClick={Schedule}
            >
              Schedule
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleDateModal;
