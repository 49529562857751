import React, { useEffect, useLayoutEffect, useState } from 'react';
import BirthDayAnniversary from './BirthDayAnniversary/BirthDayAnniversary';
import Announcements from './BirthDayAnniversary/Announcements';
import EmployeeTable from '../../components/EmployeeTable/EmployeeTable';
import EmployeeAttendance from './EmployeeAttendance/EmployeeAttendance';
import EmployeeTypeChart from './EmployeeChart/EmployeeTypeChart';
import ChartList from './EmployeeChart/ChartList';
import { useSelector, useDispatch } from 'react-redux';
import { setEventData } from '../../redux/slices/empEventsSlice';
import { triggerNotification, useGetApiRequests } from '../../common/CommonExport';
import DashboardHeader from './dashboardheader';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';

interface SearchEmailAnnouncementDto {
    isActive: boolean;
    organisationId: number;
    status: string;
}
interface AnnouncementPayload {
    searchEmailAnnouncementDto: SearchEmailAnnouncementDto;
    start: number;
    limit: number;
}
interface AnnouncementResponse {
    data: {
        announcements: any[]; // Replace `any` with a more specific type if known
    };
    status: {
        message: string;
    };
}
interface EmailAttachment {
    emailAttachmentId: number;
    filePath: string;
    fileName: string;
}
interface EmailRecipient {
    emailRecipientId: number;
    toEmail: string;
    ccEmail: string | null;
    bccEmail: string | null;
}
interface EmailAnnouncement {
    emailAnnouncementId: number;
    senderCntUserId: number;
    subject: string;
    body: string;
    status: string;
    scheduledTime: string | null;
    isStarred: boolean;
    attachments: EmailAttachment[];
    emailRecipient: EmailRecipient;
    isActive: boolean;
    createdOn: string;
    updatedOn: string;
    organisationId: number;
    userName: string | null;
    fromMailId: string | null;
}

export default function Dashboard() {
    const dispatch = useDispatch();
    const reduxToken = useSelector((state: any) => state?.employeeTable?.data);
    const eventData = useSelector((state: any) => state?.eventData?.eventData);
    const userData = useSelector((state: any) => state.loginUserData.userData);
    const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);
    const [expand, setExpand] = useState(false);
    const [dashboardData, setDashboardData] = useState<any>();
    const [announcementList, setAnnouncementList] = useState<EmailAnnouncement[] | undefined>();
    const [empData, setEmpData] = useState<any>();
    const [loader, setLoader] = useState<any>(false)
    const [reLoadPage, setReLoadPage] = useState<any>(false)
    const dashboard = useGetApiRequests('dashboard', 'GET');
    const getSentAnnouncements = useGetApiRequests('filterAnnouncement', 'POST');

    useEffect(() => {
        getAnnouncements();
    }, []);
    const dashboardApiCall = (param: any) => {
        // setLoader(true)
        dashboard('', param)
            .then((response: any) => {
                setTimeout(() => {
                    setLoader(false)
                }, 1000)
                if (param?.isDashboardRefresh) {
                    dispatch(setEventData(response?.data?.data));
                    setDashboardData(response?.data?.data);
                } else if (!param?.isDashboardRefresh) setEmpData(response?.data?.data);
            })
            .catch((err: any) => {
                triggerNotification('error', '', err?.response?.data?.status?.message, 'topRight');
            });
    };
    const getAnnouncements = () => {
        const payload: AnnouncementPayload = {
            searchEmailAnnouncementDto: {
                isActive: true,
                organisationId: 562,
                status: "SENT",
            },
            start: 0,
            limit: 500,
        };
        getSentAnnouncements(payload)
            .then((response) => {
                if (axios.isAxiosError(response)) {
                    throw response;
                }
                setAnnouncementList(response?.data);
                console.log(response?.data);
            })
            .catch((err) => {
                handleError(err?.message);
            });
    }
    const handleSuccess = (message: string) => {
        triggerNotification('success', '', message, 'topRight');
    };
    const handleError = (errorMessage: string) => {
        triggerNotification('error', '', errorMessage, 'topRight');
    };
    useLayoutEffect(() => {
        setLoader(true)
        if (Object.keys(eventData).length === 0) {
            const param: Object = {
                isDashboardRefresh: true,
            };
            dashboardApiCall(param);
        } else setDashboardData(eventData);

        const paramFalse: Object = {
            isDashboardRefresh: false,
            employeeDashboardFilterDto: { departmentNames: [], designationNames: [], gradeNames: [], status: 'All Employees' },
        };
        dashboardApiCall(paramFalse);
    }, [reLoadPage]);

    useLayoutEffect(() => {
        setExpand(reduxToken);
    }, [reduxToken, userData, expand]);

    const dashBoardCall = () => {
        setReLoadPage(!reLoadPage)
    }

    const hasEmployeePermission = rolePermissions && rolePermissions.some((item: any) => {
        return item?.component?.name === 'Employee' && (item?.isView || item?.isCreate || item?.isEdit || item?.isDelete);
    });

    return (
        <div className={expand ? 'dashboard-container dashboard-expanded bg-customBg' : 'dashboard-container dashboard-expand bg-customBg'}>
            {loader && <Loader />}
            {dashboardData && empData && (
                <div className='page-container bg-customBg'>
                    <DashboardHeader dashBoardCall={dashBoardCall} />
                    <div className='full-width-container'>
                        <div className='md:flex justify-between dashboard-height'>
                            <div className={expand ? 'dashboard-container__left w-full expanded' : 'dashboard-container__left w-full expand'}>
                                <div className='grid md:grid-cols-2 card bg-white mb-4 dashboard_chart'>
                                    <div className='md:w-11/12 md:flex chart_container md:border-r border-b md:border-b-0'>
                                        <div className='md:w-1/2 chart_doughnut md:flex items-center'>
                                            <EmployeeTypeChart dashboardData={dashboardData} />
                                        </div>
                                        <div className='md:w-1/2- flex items-center'>
                                            <ChartList dashboardData={dashboardData} />
                                        </div>
                                    </div>
                                    <EmployeeAttendance dashboardData={dashboardData} />
                                </div>
                                {hasEmployeePermission && <EmployeeTable dashboardData={empData} reLoadPage={reLoadPage} />}
                            </div>
                            <div className={expand ? 'dashboard-container__right expand' : 'dashboard-container__right '}>
                                <div className='dashboard-container__birth-anniversary '>
                                    <div className='birth-anniversary-bottom mb-4'>
                                        <Announcements title='Announcements' announcementList={announcementList} />
                                    </div>
                                    <div data-testid='birthdays-anniversaries-container' className='birth-anniversary-top mb-4'>
                                        <BirthDayAnniversary dashboardData={dashboardData?.currentMonthEmployeeEvents}
                                            title='Birthdays & Anniversaries' />
                                    </div>
                                    <div className='birth-anniversary-bottom'>
                                        <BirthDayAnniversary dashboardData={dashboardData?.currentMonthConfirmationAndAppraisalStatusEmployees}
                                            title='Confirmation & Appraisals Due' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}