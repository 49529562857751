import React from 'react'
import { Card } from 'antd';
import { Chapter } from '../../pages/LearningManagement/Course/Chapter';
import { AssignButton } from '../../pages/LearningManagement/Course/AssignButton';
import CourseEmployeeProfile from '../../pages/LearningManagement/Course/CourseEmployeeProfile';
import noPreview from "../../assets/images/nopreview.jpeg"

const { Meta } = Card;

const CourseCard = ({ detail, courseType, handleNavigateToCourseDetails }: any) => {
  const baseUrl = process.env.REACT_APP_ELMS_COURSE_LOGOURL;
  return (
    <div className='mx-[20px]'>
      <Card
        cover={
          detail?.courseLogoPath ?
            (<div className='relative min-h-[155px] course_backroundimg cursor-pointer' onClick={() => handleNavigateToCourseDetails(detail)}>
              <img className='max-h-[160px] w-full p-[0px] min-h-[160px]'
                alt="course_logo"
                src={`${baseUrl}${detail?.courseLogoPath}`}
                loading='lazy'
              />
              <p className={detail?.courseStatus === "publish" ? "absolute course-status bg-[#3b506f]" : "course-status absolute draft bg-[#2578F5]"}>{detail?.courseStatus === "publish" ? 'Published' : 'Draft'}</p> </div>) :
            (<div className='min-h-[155px] relative cursor-pointer' onClick={() => handleNavigateToCourseDetails(detail)}>
              <img className='max-h-[155px] w-full p-[1px] noPreviewImg ' alt="noPreview" src={noPreview} />

              <p className={detail?.courseStatus === "publish" ? "absolute course-status bg-[#3b506f]" : "course-status absolute draft bg-[#2578F5] "}>{detail?.courseStatus === "publish" ? 'Published' : 'Draft'}</p></div>)
        } >
        <Meta title={detail?.courseName} description={detail?.courseDescription} />

        <div className='flex justify-between mt-5 absolute bottom-0 left-0 w-full p-[20px]'>
          <Chapter detail={detail} />
          {detail?.courseStatus === 'publish' && <AssignButton detail={detail} />}
          {/* {courseType !== 'unassigned' && <CourseEmployeeProfile detail={detail} />} */}
        </div>
      </Card >
    </div >
  )
}
export default CourseCard