import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setScheduleInfo } from '../../../../redux/slices/announcementIdSlice';
import { useNavigate } from 'react-router-dom';

export default function ScheduledAnnouncementAlert() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scheduleInfo = useSelector((state: any) => state?.announcementIdSlice?.scheduleInfo);
  const [isActiveAlert, setIsActiveAlert] = useState(false)

  useEffect(() => {
    setIsActiveAlert(scheduleInfo.isActiveAlert)
    setTimeout(() => {
      setIsActiveAlert(false)
      dispatch(setScheduleInfo({
        isActiveAlert: false,
        data: scheduleInfo.data
      }))
    }, 5000)


  }, [scheduleInfo.isActiveAlert])

  const formatDate = (
    date: string | null | undefined) => {
    try {
      if (!date) {
        throw new Error("Date is null or undefined");
      }

      const givenDate = moment(date);

      if (!givenDate.isValid()) {
        throw new Error("Invalid date format");
      }

      const now = moment();

      if (givenDate.isSame(now, 'day')) {
        return `Today, ${givenDate.format('h:mma')}`;
      }

      if (givenDate.isSame(now.clone().subtract(1, 'day'), 'day')) {
        return `Yesterday, ${givenDate.format('h:mma')}`;
      }

      if (givenDate.isSame(now.clone().add(1, 'day'), 'day')) {
        return `Tomorrow, ${givenDate.format('h:mma')}`;
      }

      return givenDate.format('dddd, h:mma'); // For other days like "Monday, 8:30am"
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirect = () => {
    // dispatch(setAnnouncementlist(object));
    navigate("/home/announcements/single-announcements");
    //navigate(`/home/announcements/${status}/single-announcements`);
  }



  return (
    <>{isActiveAlert && <div className=' flex absolute bottom-[99%] left-[66%] space-x-4 bg-[#FFE8F0] p-5 rounded-lg'>
      <p className='text-[#434343] text-[12px] xl:text-[15px] font-normal'>Scheduled announcement for {formatDate(scheduleInfo?.data?.data?.createdOn)}  </p>
      <p className=' text-[#D34A7C] cursor-pointer' onClick={handleRedirect}>View</p>
    </div>}</>
  )
}
