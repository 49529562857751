export const companyDtoInitialValues = {
  dateOfJoin: "",
  designationEffectiveDate: "",
  nextProbationEndDate: "",
  reportingManagerId: "",
  backGroundVerification: "",
  grade: "MG6",
  designationDto: {
    designationId: "",
  },
  preDesignationDto: {
    preDesignationId: "",
  },
  statusDto: {
    statusId: "",
  },
  departmentId: "",
  employeeTypeId: "",
  officialEmailId: "",
};

export const employeeDtoInitialProfileValues = {
  firstName: "",
  lastName: "",
  dateOfBirth: null,
  bloodGroup: "",
  fatherOrSpouseName: "",
  fatherName: "",
  motherName: "",
  personalContactNo: "",
  personalEmail: "",
  aadharNumber: "",
  panNumber: "",
  uanNumber: "",
  esiNumber: "",
  gender: "M",
  isMarried: false,
  sameAsCurrentAddress: false,
  presentAddress: {
    doorNo: "",
    street: "",
    city: "",
    state: "",
    pinCode: "",
  },
  permanentAddress: {
    doorNo: "",
    street: "",
    city: "",
    state: "",
    pinCode: "",
  },
  emergencyContactNo1: "",
  emergencyContactNo1Relationship: "",
  emergencyContactNo2: "",
  emergencyContactNo2Relationship: "",
};

export const employeeAdditionalInformationDtoInitialProfileValues = {
  employeeBankDetailsDto: {
    nameAsPerBankRecord: "",
    bankName: "",
    branchName: "",
    accountName: "",
    ifscCode: "",
  },
  employeeDependentDto: [
    {
      key: 1,
      relationship: "Father",
      dependentName: "",
      dateOfBirth: "",
    },
    {
      key: 2,
      relationship: "Mother",
      dependentName: "",
      dateOfBirth: "",
    },
  ],
  employeePreviousEmployment: [
    {
      key: 1,
      companyName: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
    {
      key: 2,
      companyName: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
    {
      key: 3,
      companyName: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ],
};
