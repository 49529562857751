import { Modal } from 'antd';
import ComposeMail from '../ComposeMail';

// Define the props type for the component
interface ComposeMailModalProps {
  title: string; // Title of the modal
  setSendMail: (value: boolean) => void; // Function to toggle the sendMail state
  sendMail: boolean; // State to control modal visibility
  actionType?: string
  announcementData?: any
}

function ComposeMailModal(props: ComposeMailModalProps) {
  const { title, setSendMail, sendMail, actionType = "create", announcementData } = props;

  return (
    <div>
      <Modal
        title={title}
        className="new-message_requestion w-[1200px]"
        centered
        open={sendMail}
        onOk={() => setSendMail(false)}
        onCancel={() => setSendMail(false)}
        footer={null}
        maskClosable={false}
      >
        <ComposeMail
          status="all" s
          setSendMail={setSendMail}
          actionType={actionType}
          announcementData={announcementData} />
      </Modal>
    </div>
  );
}

export default ComposeMailModal;
