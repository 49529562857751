import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { announcementSliceRootState, fetchApiCall } from '../../../../redux/slices/announcementIdSlice';
import { useSelector } from 'react-redux';

export default function AnnouncementSidebar() {
  const { isReload } = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();

  const handleRoute = (el: string) => {
    dispatch(fetchApiCall(!isReload));
    navigate(el);
  };

  const menuItems = [
    { path: '/home/announcements/all', label: 'All announcements', icon: 'fi fi-rr-user' },
    { path: '/home/announcements/sent', label: 'Sent', icon: 'fi fi-rs-paper-plane' },
    { path: '/home/announcements/drafts', label: 'Drafts', icon: 'fi fi-rr-calendar-lines-pen' },
    { path: '/home/announcements/starred', label: 'Starred', icon: 'fi fi-rr-star' },
    { path: '/home/announcements/scheduled', label: 'Scheduled', icon: 'fi fi-rr-calendar-clock' },
    { path: '/home/announcements/trash', label: 'Trash', icon: 'fi fi-rr-trash' },
  ];

  return (
    <div className="announcement-menu">
      <ul className="announcement-menu--items">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname.startsWith(item.path) ? 'active' : ''} onClick={() => handleRoute(item.path)}
          >
            <i className={item.icon}></i>
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
