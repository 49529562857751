import { Button, DatePicker, DatePickerProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { ReactMailChips } from "react-mail-chips";

export default function FilterForm(props: any) {
  const { setIsActiveFilter, onFormUpdate } = props;
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);
  const [subjects, setSubjects] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null); // Ref for the modal container
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [chipsKey, setChipsKey] = useState<number>(0); // Add a key to force re-render

  const onChangeDate: DatePickerProps['onChange'] = (date) => {
    setSelectedDate(date);
    setErrorMessage(''); // Clear error when date changes
    // onFormUpdate({ toEmails, subjects, selectedDate: date });
  };
  const handleEmailsChange = (newEmails: string[]) => {
    setToEmails(newEmails);
    setErrorMessage(''); // Clear error when emails change
    // onFormUpdate({ toEmails: newEmails, subjects, selectedDate });
  };
  const handleSubjectsChange = (value: string) => {
    setSubjects(value);
    setErrorMessage(''); // Clear error when subject changes
    // onFormUpdate({ toEmails, subjects: value, selectedDate });
  };
  const handleSearch = () => {
    // Check if all fields are empty
    if (toEmails.length === 0 && !subjects && !selectedDate) {
      setErrorMessage('Please fill at least one field: ToEmails, Subjects, or Date.');
      return;
    }
    // Clear error and proceed with valid data
    setErrorMessage('');
    onFormUpdate({ toEmails, subjects, selectedDate }, "search");
  };
  const handleClear = () => {
    setToEmails([]);
    setSubjects('');
    setSelectedDate(null);
    setErrorMessage('');
    setChipsKey((prevKey) => prevKey + 1); // Force re-render of ReactMailChips
    onFormUpdate({ toEmails: [], subjects: '', selectedDate: null }, "clear");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const datePickerDropdown = document.querySelector('.ant-picker-dropdown');
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        (!datePickerDropdown || !datePickerDropdown.contains(event.target as Node))
      ) {
        setIsActiveFilter(false); // Close modal
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsActiveFilter]);

  return (
    <>
      <div ref={modalRef} className="filler-form">
        <div className=' h-[130px] mt-[5px]'>
          {/* <div className="flex flex-row-reverse ">
            <button onClick={() => setIsActiveFilter(false)} className=''>
              <span className="material-symbols-outlined font-[300] text-[1,9px] text-[#d34a7c] cursor-pointer filter-close">
                close
              </span>
            </button>
          </div> */}
          <div className="form-group ">
            <label>To:</label>
            <div className=' w-full h-full form-group-multiple'>
              <ReactMailChips
                key={chipsKey} // Add a dynamic key to force reset
                emails={toEmails}
                setEmails={handleEmailsChange}
                className="font-rubik text-[10px] w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="form-group " >
            <label>Subject:</label>
            <input
              type="text"
              className="form-control"
              value={subjects}
              onChange={(e) => handleSubjectsChange(e.target.value)}
            />
          </div>
          <div className="form-group" ref={datePickerRef}>
            <label className="date">Date:</label>
            <DatePicker
              value={selectedDate}
              onChange={onChangeDate}
              placeholder="Select a date"

            />
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
        <div className=" flex justify-end mt-[9px]">
          <Button shape="round" className="w-[116px] mr-4 bnt-secondary hover:opacity-60  hover:transition-opacity" onClick={handleClear}>
            Clear Filter
          </Button>
          <Button
            type="primary"
            shape="round"
            className="w-[116px] bnt-filter-search hover:opacity-60  hover:transition-opacity"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </div>
    </>
  );
}
