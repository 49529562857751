import React, { useEffect, useState } from 'react'
import DynamicTable from '../../../../components/CommonTable/DynamicTable'
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import ChapterButton from '../../../../components/AddChapterButton/ChapterButton';
import { triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { setAssessmentAssignChapterID } from '../../../../redux/slices/currentCourseDetailSlics';
import Loader from '../../../../components/Loader/Loader';
import { getChapterListByCourseId, handleSkip } from './ChapterAssessmentTableHelper';
import { DataTypeColumn } from '../../../../services/apiInterfaceType/apiInterfaceType';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const ChapterAssesmentTable = ({ handleTabChange }: any) => {
  const dispatch = useDispatch()
  const chapterListByCourseId = useGetApiRequests('chapterListByCourseId', 'GET');
  const chapterUpdateByCourseId = useGetApiRequests('chapterListByCourseId', 'PUT');
  const loggedInUser = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const currentAssessmentTab = useSelector((state: any) => state?.currentCourse?.currentAssessmentTab);
  const [chapterList, setChapterList]: any = useState([]);
  const [allChapter, setAllChapter]: any = useState<any>([]);
  const [loader, setLoader] = useState<any>(false)

  const editRowFunction = (record: any) => {
    dispatch(setAssessmentAssignChapterID(record))
    dispatch(setCurrentTab(8))
  }

  const handlePutData = async (payload: any) => {
    setLoader(true);
    try {
      const queryParams = { loggedInUserId: loggedInUser?.userId };
      const pathParams = { id: currentCourseId };
      const res: any = await chapterUpdateByCourseId(payload, queryParams, pathParams);
      if (res?.data?.status === 200)
        getChapterListByCourseId(setLoader, currentCourseId, chapterListByCourseId, setAllChapter, currentAssessmentTab, setChapterList);
      setLoader(false);
      triggerNotification('success', '', res?.data?.message, 'topRight');
    } catch (err: any) {
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
      setLoader(false);
    }
  };
  const singleDeleteFunction = (record: any) => {
    const updatedArr = allChapter?.map((item: any) => {
      if (item.courseChapterAssignmentId === record?.courseChapterAssignmentId) {
        return { ...item, quiz: null };
      }
      return item;
    });
    handlePutData(updatedArr)
  }

  useEffect(() => {
    getChapterListByCourseId(setLoader, currentCourseId, chapterListByCourseId, setAllChapter, currentAssessmentTab, setChapterList)
  }, [currentAssessmentTab])

  const handleBack = () => {
    setChapterList([])
    if (currentAssessmentTab === '2') {
      handleTabChange('1')
      return
    }
    dispatch(setCurrentTab(2))
  }

  const handleSwitchToNextSection = () => {
    setChapterList([])
    if (currentAssessmentTab === '1')
      handleTabChange('2')
    else if (currentAssessmentTab === '2') {
      dispatch(setCurrentTab(11))
    }
  }

  const columns: ColumnsType<DataTypeColumn> = [
    {
      title: 'S.No',
      dataIndex: 'key',
      key: 'key',
      width: '30%',
    },
    {
      title: (currentAssessmentTab === '1' ? 'Chapter ' : 'Course'),
      dataIndex: 'chaptername',
      key: 'chaptername',
      width: '20%',
      render: (text, record: any) => (
        <p>
          {currentAssessmentTab === '1' ? (
            <span style={{ marginLeft: '8px' }}>{text}</span>
          ) : (
            <span style={{ marginLeft: '8px' }}>{record?.courseName}</span>
          )}
        </p>
      )
    },
    {
      title: 'Assessment Name  ',
      dataIndex: 'address',
      key: 'address',
      width: '15%',
      render: (text, record: any) => (
        <p>
          {record?.quiz !== null ? (
            <span style={{ marginLeft: '8px' }}>chapter {record?.displaySequence} quiz</span>
          ) : (
            <ChapterButton chapId={record} />
          )}
        </p>
      )
    },
    {
      title: 'No.of Question',
      dataIndex: 'age',
      key: 'age',
      width: '15%',
      render: (text, record: any) => (
        <p>
          {record?.quiz !== null ? (
            <span style={{ marginLeft: '8px' }}>{record?.quiz?.questionaires?.length}</span>
          ) : (
            <span style={{ marginLeft: '8px' }}>{''}</span>
          )}
        </p>
      )
    },
    {
      title: 'Pass Criteria  ',
      dataIndex: 'address',
      key: 'address',
      width: '10%',
      render: (text, record: any) => (
        <p>
          {record?.quiz !== null ? (
            <span style={{ marginLeft: '8px' }}>{record?.quiz?.noOfQuestionsToBePass}</span>
          ) : (
            <span style={{ marginLeft: '8px' }}>{''}</span>
          )}
        </p>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      render: (_: any, record: any) => (
        <div className='flex'>
          <Tooltip placement="top" title="Edit">
            <span className="material-symbols-outlined cursor-pointer hover:text-primary text-lg pr-3" onClick={() => editRowFunction(record)} >
              Edit
            </span>
          </Tooltip>
          {record?.quiz !== null &&
            <Tooltip placement="top" title="Delete">
              <span className="material-symbols-outlined cursor-pointer hover:text-primary text-lg" onClick={() => singleDeleteFunction(record)}>
                Delete
              </span>
            </Tooltip>
          }
        </div>
      ),

    }
  ]

  return (
    <div className='course-table min-h-[450px] bg-[#F5F5F5] relative'>
      {loader && <ModalLoader />}
      {chapterList?.length !== 0 &&
        <DynamicTable columns={columns} data={chapterList} />
      }
      <div className="course-button">
      </div>
      {(chapterList?.length === 0 && currentAssessmentTab === '2') && (
        <div className="course-button">
          <ChapterButton />
        </div>
      )}
      <div className="footer bg-white chapter-assessment-footer">
        <div className='mt-5 mb-4 flex justify-end '>
          <div className="btnGroup flex justify-end">
            <button className='addButton mr-4 primary px-5 cancelElms' type="submit" onClick={handleBack} >Back</button>
            <button className='addButton mr-4 primary px-5 cancelElms' type="submit" onClick={() => handleSkip(setChapterList, handleTabChange, dispatch)} >Skip</button>
            <button className='addButton  primary px-5 confirmElms' type="submit" onClick={handleSwitchToNextSection}  >Save & Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChapterAssesmentTable
