import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AnnouncementIdState {
    announcementIdList: number[];
    isReload: boolean;
    scheduleInfo: {};
    isActiveListening: boolean,
    deleteAll: number[];
    announcementId: number | null;
}

const initialState: AnnouncementIdState = {
    announcementIdList: [],
    isReload: true,
    scheduleInfo: {
        isActiveAlert: false,
        data: {},
    },
    isActiveListening: false,
    deleteAll: [],
    announcementId: null
};

const announcementIdSlice = createSlice({
    name: "announcementId",
    initialState,
    reducers: {
        addAnnouncementId: (state, action: PayloadAction<number>) => {
            // Add a single ID if it doesn't already exist

            if (!state.announcementIdList.includes(action.payload)) {
                state.announcementIdList.push(action.payload);
            }
        },
        removeAnnouncementId: (state, action: PayloadAction<number>) => {
            // Remove a specific ID
            state.announcementIdList = state.announcementIdList.filter((id) => id !== action.payload);
        },
        clearAnnouncementIdList: (state) => {
            state.announcementIdList = [];
        },
        fetchApiCall: (state, action: PayloadAction<boolean>) => {
            state.isReload = action.payload;
        },
        setScheduleInfo: (state, action: PayloadAction<any>) => {
            state.scheduleInfo = action.payload;
        },
        setIsActiveListening: (state, action: PayloadAction<boolean>) => {
            state.isActiveListening = action.payload;
        },
        setDeleteAll: (state, action) => {
            state.deleteAll = action.payload;
        },
        setAnnouncementId: (state, action) => {
            state.announcementId = action.payload;
        }
    },
});

export const { addAnnouncementId, removeAnnouncementId,
    clearAnnouncementIdList, fetchApiCall,
    setScheduleInfo, setDeleteAll,
    setAnnouncementId } = announcementIdSlice.actions;

export default announcementIdSlice.reducer;


export interface announcementSliceRootState {
    announcementIdSlice: AnnouncementIdState;
}