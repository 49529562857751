import React from "react";
import Calendar from "react-calendar";
import './BigCalender.scss'
import { isBefore, addDays } from 'date-fns';

const BigCalender = ({ selectedDate, onChange, readOnly }: any) => {

  const tileDisabled = ({ date }: any) => {
    const twoDaysBefore = addDays(new Date(), -1);
    return isBefore(date, twoDaysBefore);
  };

  const formatShortWeekday = (locale: any, date: any) => {
    const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    return daysOfWeek[date.getDay()];
  };

  return (
    <Calendar
      className={readOnly ? 'custom-calendar' : ''}
      onChange={onChange}
      value={selectedDate}
      tileDisabled={tileDisabled}
      formatShortWeekday={formatShortWeekday}
    />
  )
}

export default BigCalender