import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const DesignationEditor = (props: any) => {
    const [responsibilities, setResponsibilities] = useState('');
    const htmlContent = props?.editRecord?.quilldata;

    useEffect(() => {
        if (props?.editModal === true) {
            const quill = document.querySelector('.ql-editor');
            if (quill) {
                quill.innerHTML = htmlContent;
                setResponsibilities(htmlContent);
                props?.setValue('roles', htmlContent);

            }
        } else {
            props?.setValue('roles', '');
        }
    }, []);

    const handleChange = (value: any) => {
        setResponsibilities(value);
        props?.setValue('roles', value);
    };
    const modules = {
        toolbar: false, // Disable the toolbar (which includes tooltips)
    };

    const placeholder = 'Enter roles and responsibilities...';

    return (
        <div className='ql ql_text-editor'>
            <ReactQuill value={responsibilities}
                onChange={handleChange}
                placeholder={placeholder} modules={modules} />
        </div>
    );
};

export default DesignationEditor;
