import React from 'react'
import { formatDate } from '../../formattedDate';
import MailDate from './MailDate';

export default function MailAction({ data }: any) {
  return (
    <div className='mail-status'>
      <span>{data && data.status}</span>
      {/* {data && data.scheduledTime && <MailDate dateTime={data.scheduledTime} />} */}
    </div>
  )
}
