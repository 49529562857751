interface EmailRecipientRowProps {
  label: string; // "cc" or "bcc"
  emailList: string | undefined;
}

const EmailRecipientRow: React.FC<EmailRecipientRowProps> = ({ label, emailList }) => {
  if (!emailList) return null;

  return (
    <tr>
      <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] text-[14px]">
        {label}:
      </td>
      <td className="w-[77%]">
        <div>
          {emailList
            .split(',')
            .map((email, index, array) => (
              <p key={index} className="text-black">
                {email.trim()}
                {index < array.length - 1 && ','} {/* Add a comma except for the last item */}
              </p>
            ))}
        </div>
      </td>
    </tr>
  );
};

export default EmailRecipientRow;