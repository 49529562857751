import React, { useEffect } from 'react'
import Dollar from "../../../assets/images/client-onboard/dollar.png"
import { useNavigate } from 'react-router-dom';
import useRazorpay from '../RazorPaySetup';

const PlanCard = (props: any) => {
  const { displayRazorpay } = useRazorpay();
  const { basicPlan, type } = props;
  const navigate = useNavigate()
  const typePrices: any = {
    Free: 0,
    Business: 14,
    Enterprise: 20,
  };
  const typeDescriptions: any = {
    Free: '(Up to 20 users)',
    Business: '(Above 20 users)',
    Enterprise: <br />,
  };
  const price: any = typePrices[type];
  const description: any = typeDescriptions[type];

  useEffect(() => {

  }, [props])

  const navigateCurrentPlan = (type: string) => {
    const params: any = {
      Business: { key: 'business' },
      Enterprise: { key: 'enterprise' },
      Free: { key: 'free' },
    };
    if (type === 'Free') {
      const urlWithParamsFree = `/signup?${new URLSearchParams(params[type]).toString()}`;
      navigate(urlWithParamsFree);
    } else {
      displayRazorpay(type === 'Business' ? 1000 : 2000, type)
    }
    const urlWithParams = `/signup${type === 'Free' ? '' : '/payments'}?${new URLSearchParams(params[type]).toString()}`;

  }

  const handelGetStart = (type: string) => {
    console.log("type:", type)
    navigateCurrentPlan(type)
  };

  const getStyle = (value: any) => {
    if (value === "Free")
      return "bg-[#D3D3D3]"
    else if (value === "Business")
      return "bg-[#35C909]"
    else
      return "bg-[#FF8B07]"
  }

  return (
    <div className={`${"plan-cards w-full flex "} ${(type === "Free" || type === "Enterprise") ? "mt-12" : " popular-plan"}  `}>
      <div className="w-full mx-[20px]">
        <div className="basic-plan bg-[#ffffff] p-10 rounded-xl]">
          {type === "Business" && <p className='plan-popular absolute flex justify-center items-center'>Popular</p>}
          <div className={`${type === "Business" ? "mt-10" : ""} ${"flex justify-center items-center flex-col pb-10"}`}>
            <p className='text-[#0C0C0C] font-rubik text-[30px] font-medium'>{type}</p>
            <p className='text-[#5B5B5B] font-rubik text-[18px] '>
              {description && <span>{description}</span>}
            </p>
            <span className={`${getStyle(type) + " " + " rounded - md mt-3 w-[46px] h-[7px]"}`}></span>
            <div className="basic-price flex justify-center items-center flex-col pb-12">
              <p className='flex'>
                <sub><img src={Dollar} alt="" className='object-contain' /></sub>
                <span className='text-[#D14C7E] text-[54px] font-rubik font-medium'>
                  {price}
                  {price !== 0 && <span className='text-[30px]'>.99</span>}
                </span>
              </p>
              {price === 0 && <br />}
              {price !== 0 && <p className='text-[18px] text-[#8F8F8F] font-normal'>Per active seat, per month</p>}
            </div>
            <button className={`${'addButton w-[400px] font-medium text-[20px] text-[#D34B7C]'} ${type === "Free" ? "basic_plan " : ""} `} onClick={() => handelGetStart(type)}>
              Get Started
            </button>
          </div>
          <div className="plan-description pl-8">
            <ul>
              {basicPlan && basicPlan.map((feature: any, index: number) => (
                <li key={`${index + 1}`} className='text-[#464646] pb-5  font-rubik text-[18px] font-normal'>{feature}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}

export default PlanCard
