import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

interface AnnouncementScheduleState {
    scheduleDate: any
    scheduleTime: any,
    scheduleAnnouncement: boolean | number
}

const initialState: AnnouncementScheduleState = {
    scheduleDate: null, // Default to null
    scheduleTime: null, // Default to null
    scheduleAnnouncement: false
};

const AnnouncementScheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setScheduleDate: (state, action: PayloadAction<Dayjs>) => {
            state.scheduleDate = action.payload;
        },
        setScheduleTime: (state, action: PayloadAction<Dayjs>) => {
            state.scheduleTime = action.payload;
        },
        setScheduleAnnouncement: (state, action: PayloadAction<boolean | number>) => {
            state.scheduleAnnouncement = action.payload;
        },
        resetScheduleAnnouncement: (state, action: PayloadAction<boolean | number>) => {
            state.scheduleAnnouncement = false;
        },
        resetSchedule: (state) => {

            state.scheduleDate = null; // Reset to null
            state.scheduleTime = null; // Reset to null
        },
    },
});

export const { setScheduleDate,
    setScheduleTime,
    setScheduleAnnouncement,
    resetScheduleAnnouncement,
    resetSchedule
} = AnnouncementScheduleSlice.actions;

export default AnnouncementScheduleSlice.reducer;
