import React, { useEffect, useState } from 'react';
import { useGetApiRequests, useNavigate, useDispatch, useForm, SubmitHandler, Controller, yupResolver, Button, Input, storeData, Heading, ErrorMessage, triggerNotification } from '../../common/CommonExport';
import { setToken } from '../../redux/slices/authSlice';
import { LoginFormSchema } from './LoginFormSchema';
import showPassword from '../../assets/images/eyeOpen.svg';
import hidePassword from '../../assets/images/eyeClose.svg';
import { setUserData } from '../../redux/slices/userDataSlics';
import GoogleLogin from './GoogleLogin';
import { setOrgId } from '../../redux/slices/orgSlice';
import Loader from '../../components/Loader/Loader';
import { setRolePermissions } from '../../redux/slices/rolePermissionsSlice';
import { setUserId } from '../../redux/slices/userIdSlice';
import { setOrgName } from '../../redux/slices/OrganisationNameSlice';

interface LoginFormPayload {
    email: string;
    password: string;
}

const LoginForm: React.FC = () => {
    const LoginApi = useGetApiRequests('login', 'POST');
    const loginUserDetails = useGetApiRequests('userLoginDetails', 'GET');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
    } = useForm<LoginFormPayload>({
        resolver: yupResolver(LoginFormSchema) as any,
    });

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
        const doc = document.getElementById("root")
        setTimeout(() => {
            doc?.click()

        }, 2000);
    }, [])
    const handleGetEmployeeDetails = async (employeeId: any) => {
        try {
            const response: any = await loginUserDetails('', {}, { employeeId })
            if (response?.data?.status?.success === 'Success') {
                dispatch(setRolePermissions(response?.data?.data?.role?.rolePermissions));
                dispatch(setUserData(response.data));
                dispatch(setOrgName(response.data.data?.role?.organisation?.name));

                storeData('userId', response?.data?.data?.userId, true);
                storeData('loginUserData', response?.data?.data, true);
                sessionStorage.setItem("hrDeptId", response?.data?.data?.role?.organisation?.hrDeptId)
                storeData('isLoggedIn', true, true);
                navigate('/home/dashboard');
                setLoader(false)
            }
        } catch (err: any) {
            setLoader(false);
            triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
        }
    }

    const onSubmit: SubmitHandler<LoginFormPayload> = (data: LoginFormPayload) => {
        const payload: any = {
            email: data.email,
            password: data.password,
            keycloakClientId: 0
        };

        setLoader(true);
        LoginApi(payload)
            .then((response: any) => {
                const employeeId = JSON.parse(response?.data?.data?.employeeId)[0]
                storeData('loggedInEmployeeId', employeeId, true);
                storeData('authToken', response?.data?.data?.accessToken, true);
                handleGetEmployeeDetails(employeeId)
                dispatch(setOrgId(JSON.parse(response?.data?.data?.organisationId)[0]));
                dispatch(setUserId(employeeId));
                dispatch(setToken(response?.data?.data?.accessToken));
            })
            .catch((err: any) => {
                setLoader(false);
                triggerNotification('error', '', err?.response?.data?.message ?? err?.message, 'topRight');
            });
    };

    const handleResetPassword = () => {
        const formData = getValues();

        if (formData.email) {
            navigate(`/forgot-password?email=${formData?.email}`);
        } else {
            navigate('/forgot-password')
        }
    }
    return (
        <div className='w-full min-h-screen flex items-center justify-center login-form '>
            <div className='w-full mx-auto my:auto bg-transparent sign-input'>
                <Heading title='Sign In' subheading='Sign in to your account' />
                <div className='w-9/12 mt-12'>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className='relative z-0 mt-5 sign_in'>
                            <Controller name='email' control={control} render={({ field }) => <Input type='email' id='email' label='Email*' control={control} {...field} hasError={!!errors.email} />} />
                        </div>
                        {errors.email && <ErrorMessage message={errors.email.message} />}
                        <div className='relative z-0 mt-10 sign_in'>
                            <Controller name='password' control={control} render={({ field }) => <Input type={passwordVisible ? 'text' : 'password'} id='password' label='Password*' control={control} {...field} hasError={!!errors.password} />} />
                            <img className='cursor-pointer eyeIcon right-0 absolute w-5 top-3 right-2' alt='passwordVisibility' title={passwordVisible ? 'Show password' : 'Hide password'} src={passwordVisible ? showPassword : hidePassword} onClick={() => togglePasswordVisibility()} />
                        </div>
                        {errors.password && <ErrorMessage message={errors.password.message} />}
                        <div className='flex items-center justify-between mt-16 mb-12 login-forgot-password'>
                            {/* <a className='font-roboto text-sm font-medium' href='/forgot-password'>Forgot Password?</a> */}
                            <p className='font-roboto text-sm font-medium cursor-pointer text-indigo-600' onClick={() => handleResetPassword()}>Forgot Password?</p>
                            <Button type='submit' variant='primary' label='SIGN IN' />
                        </div>
                    </form>
                    <div className='mt-8 mb-8'>
                        <h2 className='border-gray-300 w-full text-center border-b leading-3 '>
                            <span className='bg-white px-6 py-4'>or</span>
                        </h2>
                    </div>
                    <div>
                        <GoogleLogin />
                    </div>
                </div>
            </div>
            {loader && <Loader />}
        </div>
    );
};

export default LoginForm;
