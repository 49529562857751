import React, { useState, useEffect, useRef } from 'react';
import { EditorState, RichUtils, CompositeDecorator, ContentState, convertFromHTML } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import 'draft-js/dist/Draft.css';
import "./draft.css"
import '@draft-js-plugins/emoji/lib/plugin.css';
import { stateToHTML, } from 'draft-js-export-html';
import { Modal, Space, Input, Button } from 'antd'
import type { MenuProps } from 'antd';
import { ReactMailChips } from "react-mail-chips";
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector, retrieveData } from '../../common/CommonExport';
import {
  onBoldClick, onItalicClick, onUnderlineClick,
  onBulletedListClick, onNumberedListClick, onUndo, onRedo, convertTo24HourFormat, addLinkRef
} from './draftHelpers';
import ComposeMailToobar from './ComposeMailToobar';
import EditorToolbar from './EditorToolbar';
import { resetSchedule, setScheduleAnnouncement, setScheduleDate, setScheduleTime } from '../../redux/slices/announcementScheduleSlice';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { ResponseAnnouncement, EmailAnnouncementDto, ErrorResponse } from './SingleAnnouncementInterface';
import { fetchApiCall, setScheduleInfo, announcementSliceRootState } from '../../redux/slices/announcementIdSlice';
import { useNavigate } from 'react-router-dom';
import { GetAnnouncementDataProps, redirectRoutes, Attachment } from './ComposeMailUtiles';
import FilesUploadedList from './FilesUploadedList';

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const Link: React.FC<{
  contentState: ContentState;
  entityKey: string;
  children: React.ReactNode;
}> = (props) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      }, callback);
    },
    component: Link,
  },
]);

const ComposeMail = (props: any) => {
  const { scheduleDate, scheduleTime } = useSelector((state: any) => state.AnnouncementScheduleSlice);
  const announcementSlice = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const { isReload } = announcementSlice;
  const orgId = useSelector((state: any) => state?.organisationId?.id);

  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty(decorator));
  const [cc, setCc] = useState<string[]>([]);
  const [bcc, setBcc] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [openCc, setOpenCc] = useState(false);
  const [openBcc, setOpenBcc] = useState(false);
  const [iconModal, setIconModal] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [attachedFile, setAttachedFile] = useState<File[]>([]);
  const [getAttachedFileNameList, setGetAttachedFileNameList] = useState<Attachment[]>([]);
  const [removedFiles, setRemovedFiles] = useState<Attachment[]>([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [linkURL, setLinkURL] = useState('');
  const [emailAnnouncementId, setEmailAnnouncementId] = useState<number | null | string>(null);
  const [sendBtnLoading, setSendBtnLoading] = useState<boolean>(false);
  const [draftBtnLoading, setDraftBtnLoading] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const createdOn = new Date().toISOString();
  const userId = retrieveData('userId', true);

  const dispatch = useDispatch();
  const createAnnouncement = useGetApiRequests('createAnnouncement', 'POST');
  const updateAnnouncement = useGetApiRequests('updateAnnouncement', 'PUT');
  const getAnnouncement = useGetApiRequests('getAnnouncement', 'GET');
  const navigate = useNavigate();
  const formattedScheduleDate = scheduleDate ? dayjs(scheduleDate).format('YYYY-MM-DD') : null;
  const formattedScheduleTime = scheduleTime ? dayjs(scheduleTime).format('hh:mm A') : null;
  const openLinkModal = () => setLinkModalVisible(true);
  const closeLinkModal = () => { setLinkModalVisible(false); setLinkURL(''); };

  useEffect(() => {
    if (props.actionType && props.actionType == 'edit') {
      getAnnouncementDetails(props);
    }
  }, []);

  useEffect(() => {
    dispatch(resetSchedule());
  }, [dispatch]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [attachedFile, getAttachedFileNameList]);
  const getAnnouncementDetails = (props: GetAnnouncementDataProps) => {
    if (props) {
      const emailAnnouncementId: number = props.announcementData.emailAnnouncementId
      if (emailAnnouncementId) {
        getSingleAnnouncementDetails(emailAnnouncementId);
      }
    }
  }
  const getSingleAnnouncementDetails = (id: number) => {
    const pathParams = { id };
    setEmailAnnouncementId(id);
    getAnnouncement('', '', pathParams)
      .then((response: AxiosResponse<ResponseAnnouncement> | AxiosError) => {
        if (response && !(response instanceof AxiosError)) {
          const { status, message, data: announcementData } = response.data;
          if (status === 200) {
            const _toEmail = response.data.data.emailRecipient.toEmail?.split(',') ?? [];
            const _ccEmail = response.data.data.emailRecipient.ccEmail?.split(',') ?? [];
            const _bccEmail = response.data.data.emailRecipient.bccEmail?.split(',') ?? [];
            const _subject = response.data.data.subject ?? '';
            const _body = response.data.data.body;
            // setEditorState(_body);
            setEmails(_toEmail);
            setCc(_ccEmail);
            setBcc(_bccEmail);
            setSubject(_subject);
            const files = response.data.data.attachments || []; // Adjust according to your response structure             
            const mappedFiles: Attachment[] = files.map((file: any) => ({
              fileName: file.fileName,
              filePath: file.filePath,
              emailAttachmentId: file.emailAttachmentId,
            }));
            // Set the mapped files into the state
            setGetAttachedFileNameList(mappedFiles);

            const blocksFromHTML = convertFromHTML(_body);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            );

            try {
              const { scheduledTime } = response.data.data;
              if (scheduledTime) {
                const _dateTime = dayjs(scheduledTime);
                dispatch(setScheduleDate(_dateTime));
                dispatch(setScheduleTime(_dateTime));
              }
            } catch (error) {
              console.error("Error while processing scheduled time:", error);
            }
            setEditorState(EditorState.createWithContent(state)); // Set updated EditorState
          } else {
            triggerNotification('error', '', message || 'Unexpected error', 'topRight');
          }
        } else {
          triggerNotification('error', '', 'Invalid response format', 'topRight');
        }
      })
      .catch((err: AxiosError) => {
        const errorResponse = err.response?.data as ErrorResponse;  // Use type assertion to access the `message` property safely
        const errorMessage = errorResponse?.message || 'Something went wrong';
        triggerNotification('error', '', errorMessage, 'topRight');
      });
  };

  // Add link to the editor
  const addLink = async () => {
    if (linkURL.trim()) {
      let content = addLinkRef(linkURL.trim(), editorState);
      setEditorState(content);
    }
    // Close the link modal after applying the link
    closeLinkModal();
  };

  const handleIconModal = () => { setIconModal((prev) => !prev); };

  const validateEmailData = () => {
    if (!emails.length) return triggerNotification('warning', '', 'Recipient email(s) cannot be empty.', 'topRight');
    if (!subject.trim()) return triggerNotification('warning', '', 'Subject cannot be empty.', 'topRight');
    if (!stateToHTML(editorState.getCurrentContent()).trim()) return triggerNotification('warning', '', 'Email body cannot be empty.', 'topRight');
    return true;
  };
  const handleToSendMail = (type: string) => {
    if (validateEmailData()) {
      const contentAsHtml = stateToHTML(editorState.getCurrentContent());
      let checkSchedule = false;
      let processType = type;
      const payload: { emailAnnouncementDto: EmailAnnouncementDto } = {
        "emailAnnouncementDto": {
          "senderCntUserId": userId,
          "subject": subject,
          "body": contentAsHtml,
          "status": type,
          "isStarred": false,
          "emailRecipient": {
            "toEmail": emails.join(','),
            "ccEmail": cc.length === 0 ? null : cc.join(','),
            "bccEmail": bcc.length === 0 ? null : bcc.join(',')
          },
          // "isActive": true,
          "createdOn": createdOn,
          "organisationId": orgId
        }
      };
      if (emailAnnouncementId !== null) {
        payload.emailAnnouncementDto.emailAnnouncementId = emailAnnouncementId;
      }
      if (formattedScheduleDate && formattedScheduleTime) {
        const formattedTime24 = convertTo24HourFormat(formattedScheduleTime);
        const scheduledTime = `${formattedScheduleDate}T${formattedTime24}:00Z`;
        payload.emailAnnouncementDto.scheduledTime = scheduledTime;
        payload.emailAnnouncementDto.status = type === 'DRAFT' ? 'DRAFT' : 'SCHEDULED';
        processType = type === 'DRAFT' ? 'DRAFT' : 'SCHEDULED';
        checkSchedule = true;
      }

      if (props.actionType === 'edit') {
        payload.emailAnnouncementDto.attachments = getAttachedFileNameList;
      }
      const formData = new FormData();  // Prepare FormData for file attachments
      if (attachedFile && attachedFile.length > 0) {
        attachedFile.forEach((file) => {
          formData.append('files', file); // Append each file under the 'files' key
        });
      } else {
        formData.append('files', "");
      }

      const headers = { 'Content-Type': 'multipart/form-data' };

      const apiCall = props.actionType === 'edit' ? updateAnnouncement : createAnnouncement;
      const pathParams = props.actionType === 'edit' ? { emailAnnouncementId: props.announcementData.emailAnnouncementId } : {};
      if (type == 'SENT') {
        setSendBtnLoading(true);
      } else {
        setDraftBtnLoading(true);
      }
      apiCall(formData, payload, pathParams, headers)
        .then((response: any) => {
          if (axios.isAxiosError(response)) {
            throw response;
          }
          const responseStatus = response?.status
          if (responseStatus === 200 || responseStatus === 201) {
            handleApiResponse(response, checkSchedule, processType);
          }
        })
        .catch(() => handleApiError())
        .finally(() => {
          setDraftBtnLoading(false);
          setSendBtnLoading(false);
        });
    }
  }
  const handleApiResponse = (response: any, checkSchedule: boolean, processType: string) => {
    if (checkSchedule) {
      const storeSchedule = response?.data;
      const id = storeSchedule?.emailAnnouncementId;
      dispatch(setScheduleInfo({
        isActiveAlert: true,
        data: storeSchedule
      }));
      dispatch(setScheduleAnnouncement(id));
    }
    dispatch(fetchApiCall(!isReload));
    triggerNotification('success', '', response?.data?.message, 'topRight');
    props?.setSendMail(false); // Close the modal after success
    if (processType) {
      const route = redirectRoutes[processType];
      if (route) {
        navigate(route);
      }
    }
  };
  // Helper function to handle API errors
  const handleApiError = () => {
    triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
    props?.setSendMail(false);
  };
  const handleKeyCommand = (command: any, state: any) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files); // Convert the FileList to an array and update state
      setAttachedFile(prevFiles => [...prevFiles, ...newFiles]);
    }
  };
  const handleFileSelect = () => {
    document.getElementById('announcementFileUpload')?.click();
  }
  const handleRemoveAttachment = (index: number) => {
    setAttachedFile((prevFiles) => prevFiles.filter((_, i: number) => i !== index));
  }
  // Wrapper function to handle updates
  const handleEmailsChange = (field: 'emails' | 'cc' | 'bcc', newEmails: string[]) => {
    const trimmedEmails = newEmails.map(email => email.trim());
    let duplicateEmails: string[] = [];
    const filteredEmails = trimmedEmails.filter(email => {
      const isDuplicate =
        (field === 'emails' && (cc.includes(email) || bcc.includes(email))) ||
        (field === 'cc' && (emails.includes(email) || bcc.includes(email))) ||
        (field === 'bcc' && (emails.includes(email) || cc.includes(email)));

      if (isDuplicate) duplicateEmails.push(email);
      return !isDuplicate;
    });

    if (duplicateEmails.length > 0) {
      triggerNotification('warning', '', `Duplicate email not allowed`, 'topRight'
      );
    }

    if (field === 'emails') setEmails(filteredEmails);
    if (field === 'cc') setCc(filteredEmails);
    if (field === 'bcc') setBcc(filteredEmails);
  };
  // Function to handle click outside
  const handleClickOutside = (event: any) => {
    if (event) {
      setOpenCc(false);
      setOpenBcc(false)
    }
  };
  const removeAttachedFile = (index: number) => {
    const removedItem = getAttachedFileNameList[index];
    const updatedFilesList = getAttachedFileNameList.filter((_, i) => i !== index);
    setGetAttachedFileNameList(updatedFilesList);
    setRemovedFiles((prev) => [...prev, removedItem]);
  };

  return (
    <div>
      <div className='w-full h-auto pl-[10px]'>
        <div className=" flex items-center  border-b mt-6 justify-between">
          <div className=' flex items-center w-[91%]'>
            <label className="block text-[#D34A7C] font-[400] text-[18px]  mb-1 font-rubik" htmlFor="to">
              To:
            </label>
            <ReactMailChips emails={emails} setEmails={(newEmails) => handleEmailsChange('emails', newEmails)} className="font-rubik text-[16px]  w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline" />
          </div>
          {!openCc ? (
            <div className=' flex  w-[9%]'>
              <p className=' px-2 text-[#707070] text-[17px] font-rubik cursor-pointer hover:underline' onClick={() => setOpenCc(true)}>Cc</p>
              <p className=' px-2 text-[#707070] text-[17px] font-rubik cursor-pointer hover:underline' onClick={() => setOpenBcc(true)}>Bcc</p>
            </div>
          ) : null}
        </div>
        {openCc && (
          <div className='border-b mt-2 flex items-center  justify-between'>
            <div className=' flex items-center w-[93%]'>
              <label className="block text-[#D34A7C] font-[400] text-[18px]  mb-1 font-rubik" htmlFor="cc">
                Cc:
              </label>
              <ReactMailChips emails={cc} setEmails={(newEmails) => handleEmailsChange('cc', newEmails)} className="font-rubik text-[16px]  w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline" />
            </div>
            {!openBcc ? (
              <div className=' flex  w-[7%]'>
                <p className=' px-2 text-[#707070] text-[17px] font-rubik cursor-pointer' onClick={() => setOpenBcc(true)}>Bcc</p>
              </div>
            ) : null}
          </div>
        )}
        {openBcc && (
          <div className='border-b mt-2 flex items-center  justify-between'>
            <div className=' flex items-center w-[98%]'>
              <label className="block text-[#D34A7C] font-[400] text-[18px]  mb-1 font-rubik" htmlFor="bcc">
                Bcc:
              </label>
              <ReactMailChips emails={bcc} setEmails={(newEmails) => handleEmailsChange('bcc', newEmails)} className="font-rubik text-[16px]  w-full py-2 px-3 border-b-0 focus:outline-none focus:shadow-outline" />
            </div>
            <div>
            </div>
          </div>
        )}

        <div className="mb-2 flex items-center border-b py-4">
          <label className="block text-[#D34A7C] font-[400]  text-[18px] font-rubik" htmlFor="subject">
            Subject:
          </label>
          <input
            className="w-full  px-3 border-b-0 font-medium text-[17px] font-rubik focus:outline-none focus:shadow-outline"
            id="subject"
            type="text"
            placeholder=""
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div ref={containerRef} className={`body-content   ${iconModal ? "body-content-edit" : ""} `}>
          <input type="file" accept="image/*" id="announcementFileUpload" name="announcementFileUpload"
            onChange={handleImageUpload} style={{ display: 'none' }} multiple />
          <Editor
            onFocus={handleClickOutside}
            editorState={editorState}
            onChange={(newEditorState) => setEditorState(newEditorState)}
            handleKeyCommand={handleKeyCommand}
            plugins={[emojiPlugin]}
          />

          <FilesUploadedList
            attachedFile={attachedFile}
            getAttachedFileNameList={getAttachedFileNameList}
            iconModal={iconModal}
            handleRemoveAttachment={handleRemoveAttachment}
            removeAttachedFile={removeAttachedFile}
          />
        </div>
      </div>
      <div className=' flex justify-between border-t pt-[10px]'>

        <Modal title="Insert Link" visible={linkModalVisible} onOk={addLink} onCancel={closeLinkModal}>
          <Input placeholder="Enter URL" value={linkURL} onChange={(e) => setLinkURL(e.target.value)} />
        </Modal>

        {iconModal && (
          <EditorToolbar editorState={editorState} setEditorState={setEditorState} openCc={openCc}
            onUndo={onUndo} onRedo={onRedo} onBoldClick={onBoldClick} onItalicClick={onItalicClick}
            onUnderlineClick={onUnderlineClick} onBulletedListClick={onBulletedListClick}
            onNumberedListClick={onNumberedListClick}
          />
        )}
        {
          <ComposeMailToobar
            handleIconModal={handleIconModal} handleFileSelect={handleFileSelect}
            formattedScheduleDate={formattedScheduleDate} iconModal={iconModal}
            formattedScheduleTime={formattedScheduleTime} openLinkModal={openLinkModal}
          />
        }
        <div className=' Dynamic-emoji'>
          <EmojiSuggestions />
          <EmojiSelect />
        </div>

        <div className=' space-x-2'>
          <Button loading={draftBtnLoading}
            className=' cursor-pointer text-[#D34A7C] bg-[#FFF5F9] border border-[#D23B7D] w-[130px]  h-[45px] rounded-3xl text-[18px] font-rubik hover:opacity-60  hover:transition-opacity'
            onClick={() => handleToSendMail('DRAFT')}>Draft</Button>
          <Button loading={sendBtnLoading}
            className=' cursor-pointer text-white bg-[#D23B7D] w-[130px]  h-[45px] rounded-3xl text-[18px] font-rubik hover:opacity-60  hover:transition-opacity'
            onClick={() => handleToSendMail('SENT')}>
            {
              formattedScheduleDate && formattedScheduleTime ? 'Scheduled' : 'Send'
            }
          </Button>
        </div>
      </div>

    </div>
  );
};
export default ComposeMail;