import React, { useEffect, useRef, useState } from 'react';
import AnnouncementHOC from './layouts/Hoc/AnnouncementHOC';
import { useNavigate } from 'react-router-dom'
import { useSelector, useGetApiRequests, triggerNotification, retrieveData } from '../../common/CommonExport';
import { Tooltip } from 'antd';
import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  ResponseAnnouncement, Announcement, AnnouncementID,
  AnnouncementState, ErrorResponse
}
  from './SingleAnnouncementInterface';
import { useDispatch } from "react-redux";
import { fetchApiCall, announcementSliceRootState } from "../../redux/slices/announcementIdSlice";
import ComposeMailModal from './modal/ComposeMailModal';
import DOMPurify from "dompurify";
import {
  OrganisationIdState
} from './layouts/mailList/mailListsInterface';
import EmailRecipientRow from './EmailRecipientRow';

const SingleAnnouncement = () => {
  const dispatch = useDispatch();
  const orgId = useSelector((state: OrganisationIdState) => state?.organisationId?.id);
  const announcementSlice = useSelector((state: announcementSliceRootState) => state.announcementIdSlice);
  const { announcementId } = announcementSlice;
  const userId = retrieveData('userId', true);

  const [showModal, setShowModal] = useState(false);
  const announcement = useSelector(
    (state: AnnouncementState) => state?.announcement
  );
  const [announcementData, setAnnouncementData] = useState<Announcement | null>(null);
  const handleShowModal = () => { setShowModal((prev) => !prev); };
  const getAnnouncement = useGetApiRequests('getAnnouncement', 'GET');
  const [sendMail, setSendMail] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>('create');

  const navigate = useNavigate();
  const setAnnouncement = useGetApiRequests("setAnnouncement", "PUT");
  const createdOn = new Date().toISOString();
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_PATH;

  const handelNavigate = () => {
    navigate(-1)
  }

  const modalRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleCloseModal = (event: MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleCloseModal);
    } else {
      document.removeEventListener('mousedown', handleCloseModal);
    }

    return () => {
      document.removeEventListener('mousedown', handleCloseModal);
    };
  }, [showModal]);

  useEffect(() => {
    if (announcement?.announcementList) {
      getSingleAnnouncementDetails(announcement.announcementList);
    }
  }, [announcement]);

  const getSingleAnnouncementDetails = (data: Announcement) => {

    const pathParams = {
      id: data.emailAnnouncementId
    }
    getAnnouncement('', '', pathParams)
      .then((response: AxiosResponse<ResponseAnnouncement> | AxiosError) => {
        if (response && !(response instanceof AxiosError)) {
          const { status, message, data: announcementData } = response.data;

          setAnnouncementData(data);
          if (status === 200) {
            console.log('Announcement Details:', announcementData);

          } else {
            triggerNotification('error', '', message || 'Unexpected error', 'topRight');
          }
        } else {
          triggerNotification('error', '', 'Invalid response format', 'topRight');
        }
      })
      .catch((err: AxiosError) => {
        // Use type assertion to access the `message` property safely
        const errorResponse = err.response?.data as ErrorResponse;
        const errorMessage = errorResponse?.message || 'Something went wrong';

        console.error('API Error:', err);
        triggerNotification('error', '', errorMessage, 'topRight');
      });
  };
  // Helper function to format the date
  const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Check if the date is today or yesterday
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    }

    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }

    // Format date as DD-MM-YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  const formatTimeUTC = (isoDate?: string) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
  };
  const handleEditComposeMail = () => {
    setActionType('edit');
    setSendMail(true);
  }
  const handleSendNow = () => {
    // Prepare FormData for file attachments
    const formData = new FormData();
    formData.append('files', "");

    const pathParams = {
      emailAnnouncementDto: announcementData?.emailAnnouncementId,
    };

    // Construct payload for submission // SCHEDULED
    const payload = {
      "emailAnnouncementDto": {
        "emailAnnouncementId": announcementData?.emailAnnouncementId,
        "senderCntUserId": userId,
        "status": "SENT",
        "isStarred": announcementData?.isStarred != null ? true : false,
        "isActive": true,
        "createdOn": createdOn,
        "organisationId": orgId,
        "subject": announcementData?.subject,
        "body": announcementData?.body,
        "emailRecipient": {
          "toEmail": announcementData?.emailRecipient?.toEmail,
          "ccEmail": announcementData?.emailRecipient?.ccEmail,
          "bccEmail": announcementData?.emailRecipient?.bccEmail
        },
        "attachments": announcementData?.attachments || []
      }
    };

    setAnnouncement(formData, payload, pathParams)
      .then((response) => {
        if (axios.isAxiosError(response)) {
          throw response;
        }
        const responseStatus = response?.status;
        if (responseStatus === 200 || responseStatus === 201) {
          triggerNotification('success', '', response?.data?.message, 'topRight');
          navigate(`/home/announcements/sent`);
          // setTimeout(() => {
          //   dispatch(fetchApiCall(!isReload));
          // }, 1000)
        }
      })
      .catch((err) => {
        triggerNotification("error", "", err?.response?.data?.message, "topRight");
      });
  }
  const getBodyData = (data: string) => {
    const sanitizedBody = data ? DOMPurify.sanitize(data) : '';
    return sanitizedBody;
  }
  const renderFilePreview = (fileExtension: string, filePath: string, fileName: string) => {
    fileExtension = fileExtension?.toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'ico'].includes(fileExtension)) {
      return (
        <img
          src={filePath}
          alt={fileName}
          className="w-[100px] h-[100px] object-cover rounded-md"
          onError={(e) => e.currentTarget.src = 'path_to_junk_image_placeholder'} // Handling junk image (invalid image)
        />
      );
    } else if (fileExtension === 'pdf') {
      return (
        <div className="flex items-center justify-center w-[100px] h-[100px] bg-red-100 rounded-md">
          <span className="material-symbols-outlined text-red-500 text-5xl">picture_as_pdf</span>
        </div>
      );
    } else if (['doc', 'docx'].includes(fileExtension)) {
      return (
        <div className="flex items-center justify-center w-[100px] h-[100px] bg-blue-100 rounded-md">
          <span className="material-symbols-outlined text-blue-500 text-5xl">description</span>
        </div>
      );
    } else if (['xls', 'xlsx'].includes(fileExtension)) {
      return (
        <div className="flex items-center justify-center w-[100px] h-[100px] bg-green-100 rounded-md">
          <span className="material-symbols-outlined text-green-500 text-5xl">table_chart</span>
        </div>
      );
    } else {
      // Junk file type handling
      return (
        <div className="flex items-center justify-center w-[100px] h-[100px] bg-gray-100 rounded-md">
          <span className="material-symbols-outlined text-gray-500 text-5xl">attachment</span>
          <div className="text-xs text-gray-600 mt-2">Unsupported file</div> {/* Marking it as junk file */}
        </div>
      );
    }
  };


  return (
    <div className='singleAnnouncement'>
      <div className='singleAnnouncement--header'>
        <div className=' flex justify-between items-center'>
          <h4 className='flex items-center'>

            <span className="material-symbols-outlined pr-4 cursor-pointer" onClick={handelNavigate}>
              keyboard_backspace
            </span>

            <span className='singleAnnouncement--header--title'> Announcement</span> </h4>
          <div className=' mr-5'>
            {
              announcementData && announcementData.status && announcementData.status == 'SCHEDULED' ? (
                <>
                  <div className=' flex flex-row-reverse'><p className=' text-[18px] font-rubik text-[#707070]'>Scheduled on</p></div>

                  <div className=' flex items-center space-x-1'>
                    <span className="material-symbols-outlined text-[#D34A7C] font-light">
                      calendar_clock
                    </span>
                    <p className="dateStyle">
                      {announcementData && announcementData.scheduledTime && formatDate(announcementData.scheduledTime)},&nbsp;&nbsp;
                      {announcementData && announcementData.scheduledTime && formatTimeUTC(announcementData.scheduledTime)}
                    </p></div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className='singleAnnouncement--body'>
        <div className='receive-mail flex items-center space-x-2'>
          <div className='profile'>
            <h5>HR</h5>
          </div>
          <div className='email-lists '>

            <h6 className=' font-medium'>{announcementData?.subject}</h6>
            <div className=' flex items-center relative'>
              <p>
                To:{' '}
                <span>
                  {announcementData?.emailRecipient?.toEmail || 'No recipient email'}
                </span>
              </p>
              <Tooltip title='Show details'>
                <span ref={tooltipRef} className="material-symbols-outlined cursor-pointer hover:bg-white ml-[2px]" onClick={() => handleShowModal()} >
                  arrow_drop_down
                </span>
              </Tooltip>
              {showModal && (
                <div ref={modalRef} className="absolute left-[100%] p-[20px] top-[43%] min-w-[430px] max-w-[50%] shadow-lg bg-white show-modal-container max-h-[300px] overflow-auto">
                  <table className="show-modal-type">
                    <tbody>
                      <tr>
                        <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] font-rubik  text-[14px]">from:</td>
                        <td className="w-[77%]">
                          <p className="font-semibold text-black">
                            <span className="font-normal text-black">&lt;noreply@cloudnowtech.com&gt;</span>
                          </p>
                        </td>
                      </tr>
                      {
                        announcementData?.emailRecipient?.toEmail && (
                          <EmailRecipientRow label="to" emailList={announcementData.emailRecipient.toEmail} />
                        )
                      }
                      {
                        announcementData?.emailRecipient?.ccEmail && (
                          <EmailRecipientRow label="cc" emailList={announcementData.emailRecipient.ccEmail} />
                        )
                      }
                      {
                        announcementData?.emailRecipient?.bccEmail && (
                          <EmailRecipientRow label="bcc" emailList={announcementData.emailRecipient.bccEmail} />
                        )
                      }
                      <tr>
                        <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] text-[14px]">date:</td>
                        <td className="w-[77%]">
                          <p className='text-black '>{announcementData ? new Date(announcementData.createdOn).toLocaleString() : 'Loading...'}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-[23%] text-right pr-4 align-baseline text-[#707070] text-[14px]">subject:</td>
                        <td className="w-[77%]">
                          <p className='text-black'>{announcementData?.subject}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              )}
            </div>
          </div>
        </div>
        <div className="mail-body h-[285px] overflow-auto">
          {/* <pre>{JSON.stringify(announcementData, undefined, 2)}</pre>
          {getBodyData(announcementData.body)}
          */}
          {announcementData ? (
            <div className=' announcement-body-desc'>
              <p className="pb-4">
                <div dangerouslySetInnerHTML={{ __html: getBodyData(announcementData.body) }} />
              </p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          {
            announcementData && announcementData?.attachments.length > 0 ? (
              <div className="flex flex-wrap gap-4">
                {
                  announcementData?.attachments?.length > 0 ? (
                    <div className="flex flex-wrap gap-4">
                      {announcementData?.attachments.map((file: any, index: number) => {
                        const fileExtension = file?.fileName?.split('.').pop()?.toLowerCase();
                        const filePath = `${imageBaseUrl}${file?.filePath}`;

                        return (
                          <div key={index} className="flex flex-col items-center bg-[#f5f5f5] p-3 rounded-lg shadow-sm">
                            <a
                              href={filePath}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm font-medium text-blue-600 hover:underline text-center truncate w-[100px]"
                              title={file?.fileName}
                            >
                              <div className="mb-2 border border-gray-300 rounded-md announcement-file" title={file?.fileName}>
                                {renderFilePreview(fileExtension, filePath, file?.fileName)}
                              </div>
                              {file?.fileName}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                }

              </div>
            ) : null
          }


        </div>
      </div>
      <div className='flex flex-row-reverse bg-[#F5F5F5]'>
        {
          announcementData?.status &&
          (announcementData.status === 'DRAFT' || announcementData.status === 'SCHEDULED') && (
            <div className='flex justify-end mb-7  mt-1 mr-8'>
              <button
                className='cursor-pointer text-[#D34A7C] bg-[#FFF5F9] border border-[#D23B7D] w-[142px] h-[45px] rounded-3xl text-[18px] mr-4 secondary  hover:opacity-60  hover:transition-opacity'
                onClick={handleEditComposeMail}>
                Edit
              </button>
              <button
                className='cursor-pointer text-white bg-[#D23B7D] w-[142px] h-[45px] rounded-3xl text-[17px]  hover:opacity-60  hover:transition-opacity'
                onClick={handleSendNow}>
                Send Now
              </button>
            </div>
          )
        }
      </div>
      {
        sendMail && <ComposeMailModal
          sendMail={sendMail}
          setSendMail={setSendMail}
          title='Draft'
          actionType={actionType}
          announcementData={announcementData} />
      }

    </div>
  )
}

export default AnnouncementHOC(SingleAnnouncement);
