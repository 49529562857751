export interface GetAnnouncementData {
  emailAnnouncementId: number;
}
export interface GetAnnouncementDataProps {
  announcementData: GetAnnouncementData;
}
export const redirectRoutes: { [key: string]: string } = {
  SENT: '/home/announcements/sent',
  DRAFT: '/home/announcements/drafts',
  SCHEDULED: '/home/announcements/scheduled',
};
export interface Attachment {
  fileName: string;
  filePath: string;
  emailAttachmentId: number;
}