import * as Yup from "yup";
import { generateEmpDependentInformationSchema, employeeDtoSchema, employeeBankDetailsDtoSchema, companyDtoSchema, personalEmailAndProfileValidateSchema, pairSchema } from "./AllSchema";
import { companyDtoInitialValues, employeeDtoInitialProfileValues, employeeAdditionalInformationDtoInitialProfileValues } from "./AllInitValues";

export const employeeValidationSchema = Yup.object().shape({
  employeeDto: employeeDtoSchema,
  employeeAdditionalInformationDto: Yup.object().shape({
    employeeBankDetailsDto: employeeBankDetailsDtoSchema,
    employeeDependentDto: generateEmpDependentInformationSchema(),
  }),
});

export const employeeProfileEditValidationSchema = Yup.object().shape({
  employeeDto: Yup.object().shape(personalEmailAndProfileValidateSchema),
  companyDto: companyDtoSchema,
});

export const employeeProfileOtherSchema = Yup.object().shape({
  employeeAdditionalInformationDto: Yup.object().shape({
    employeeBankDetailsDto: employeeBankDetailsDtoSchema,
    employeeDependentDto: generateEmpDependentInformationSchema(),
  }),
});

export const bonusSchema = Yup.object().shape({
  amount: Yup.string().matches(/^\d+$/, "Amount must be number").required("Amount is required"),
  endDate: Yup.date().required("Bonus Period Date is required"),
  startDate: Yup.date().required("Bonus Period Date is required"),
});

export const courseSchema = Yup.object().shape({
  courseDTO: Yup.object().shape({
    name: Yup.string().required("Course name is required"),
    description: Yup.string().required("Description is required"),
  }),
});

export const initialEmpFormValues = {
  employeeDto: employeeDtoInitialProfileValues,
  employeeAdditionalInformationDto: employeeAdditionalInformationDtoInitialProfileValues,
};

export const employeeProfileEditInitialValues = {
  employeeDto: { ...employeeDtoInitialProfileValues, personalEmail: "" },
  companyDto: companyDtoInitialValues,
};

export const employeeProfileOtherInitialValues = {
  employeeAdditionalInformationDto: employeeAdditionalInformationDtoInitialProfileValues,
};

export const employeeBonus = {
  amount: "",
  endDate: "",
  name: "",
  startDate: "",
  type: "project_bonus",
};

export const courseInitialState = {
  courseDTO: {
    name: "",
    description: "",
    status: "draft",
    isFinalQuiz: false,
  },
};
export const multipleChoiceQuestionSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  // explanation: Yup.string().required('Explanation is required'),
  option1: Yup.string().required("Option 1 is required"),
  option2: Yup.string().required("Option 2 is required"),
  option3: Yup.string().required("Option 3 is required"),
  option4: Yup.string().required("Option 4 is required"),
  correctAnswer: Yup.string().required("Select the Option"),
});

export const multipleChoiceQuestion = {
  questionType: "MCQ",
  question: "",
  explanation: "",
  option1: "",
  option2: "",
  option3: "",
  option4: "",
  correctAnswer: "",
};

export const fillInTheBlanksSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  correctAnswer: Yup.string().required("Correct answer is required"),
});

export const fillInTheBlanks = {
  questionType: "FIB",
  question: "",
  correctAnswer: "",
};

export const trueOrFalseSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  // explanation: Yup.string().required('Explanation is required'),
  correctAnswer: Yup.string().required("Select the Option"),
});

export const trueOrFalse = {
  questionType: "TF",
  question: "",
  explanation: "",
  correctAnswer: "",
};

export const briefQuestion = {
  questionType: "OQ",
  question: "",
  correctAnswer: "",
};

export const matchingPairsSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
  matchingHeader1: Yup.string().required("Column A Name is required"),
  matchingHeader2: Yup.string().required("Column B Name is required"),
  pair1: pairSchema,
  pair2: pairSchema,
  pair3: pairSchema,
  pair4: pairSchema,
});

export const matchingPairs = {
  questionType: "MFP",
  matchingHeader1: "",
  matchingHeader2: "",
  question: "",
  pair1: {
    option: "",
    optionMatching: "",
  },
  pair2: {
    option: "",
    optionMatching: "",
  },
  pair3: {
    option: "",
    optionMatching: "",
  },
  pair4: {
    option: "",
    optionMatching: "",
  },
};

export const arrangeInOrderSchema = Yup.object().shape({
  question: Yup.string().required("Question is required"),
});

export const arrangeInOrder = {
  questionType: "AOI",
  question: "",
};

export const questionTypeSchema = Yup.object().shape({
  questionType: Yup.string().required("Question type is required"),
});
