import MailAction from "./MailAction";
import DOMPurify from "dompurify";

export default function MailListContent({ data }: any) {
  const plainTextBody = data && data.body ? data.body : '';
  const truncateText = (text: string, maxLength: number): string => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
  const getBodyData = (data: string) => {
    const sanitizedBody = data ? DOMPurify.sanitize(data) : '';
    return sanitizedBody;
  }
  const truncatedBody = truncateText(plainTextBody, 145);
  return (
    <div className='content border-right'>
      <div className="flex w-full">
        <h5 className="mr-3"> {data && data.subject ? data.subject : '--'}</h5>
        {/* <MailAction data={data} /> */}
      </div>
      <p dangerouslySetInnerHTML={{ __html: getBodyData(truncatedBody) }} >
        {/* {truncatedBody} */}
      </p>
    </div>
  )
}
