import React, { useState } from 'react';
import Starred from './Starred';
import MailListContent from './MailListContent';
import MailSelect from './MailSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from '../../../../common/CommonExport';
import { setAnnouncementlist } from '../../../../redux/slices/announcementSlice';
import MailAction from './MailAction';

export default function MailItem({ object, status, markAll, setLoader, loader }: any) {

  const location = useLocation(); // Get the current location (URL)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Helper function to format the date
  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Check if the date is today or yesterday
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    }

    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    }

    // Format date as DD-MM-YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // Helper function to format the time
  const formatTimeUTC = (isoDate?: string) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
  };

  // Redirect to the single announcement page and set the announcement list in the store
  const handleRedirectToSingleAnnouncement = () => {
    dispatch(setAnnouncementlist(object));
    // Extract the status from the current URL after '/home/announcements/'
    const pathParts = location.pathname.split('/');
    const status = pathParts[pathParts.length - 1]; // This gives us the part before 'single-announcements'

    // navigate('/home/announcements/single-announcements');
    navigate(`/home/announcements/${status}/single-announcements`);
  };

  // Handle selecting or unselecting a mail
  const handleSelectMail = (id: string, checked: boolean) => {

  };

  return (
    <div className="flex items-center mb-4 w-full">

      <div className="mail-select mr-3">
        {/* {object?.emailAnnouncementId} */}
        <MailSelect data={object} handleSelectMail={handleSelectMail} status={status} markAll={markAll} />
      </div>

      <div className="mail-item flex items-center justify-between w-full">
        <div className="mail-starred">
          <Starred data={object} setLoader={setLoader} loader={loader} />
        </div>

        <div
          className="mail-list--content flex justify-between"
          onClick={handleRedirectToSingleAnnouncement}
        >
          <div className="w-full">
            <MailListContent data={object} />
          </div>
          <div className="mail-action pl-[20px]">
            <div className="mail-action--container">
              <div className="all-announcement--date">
                <div className="flex flex-row-reverse">
                  <MailAction data={object} />
                </div>
                <div className="flex">
                  {
                    status == 'SCHEDULED' ? (
                      <>
                        <div className="pr-[3px]">
                          <p className='text-[#434343]'>{object && object.scheduledTime && formatDate(object.scheduledTime)},</p>
                        </div>
                        <div>
                          <p className='text-[#434343]'>{object && object.scheduledTime && formatTimeUTC(object.scheduledTime)}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="pr-[3px]">
                          <p className='text-[#434343]'>{object && object.createdOn && formatDate(object.createdOn)},</p>
                        </div>
                        <div>
                          <p className='text-[#434343]'>{object && object.createdOn && formatTimeUTC(object.createdOn)}
                          </p>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
