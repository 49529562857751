import { MenuProps } from "antd";
import { EditorState, RichUtils, Modifier } from "draft-js";
// Font Family Menu Items
export const fontItems = (setFontFamily: (family: string) => void): MenuProps["items"] => [
  {
    key: "1",
    label: "Arial",
    onClick: () => setFontFamily("Arial"),
  },
  {
    key: "2",
    label: "Courier New",
    onClick: () => setFontFamily("Courier New"),
  },
  {
    key: "3",
    label: "Georgia",
    onClick: () => setFontFamily("Georgia"),
  },
  {
    key: "4",
    label: "Times New Roman",
    onClick: () => setFontFamily("Times New Roman"),
  },
  {
    key: "5",
    label: "Verdana",
    onClick: () => setFontFamily("Verdana"),
  },
];

export const onBoldClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
};

export const onItalicClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
};

export const onUnderlineClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
};

export const onAlignLeftClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "left-aligned"));
};

export const onAlignCenterClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "center-aligned"));
};

export const onAlignRightClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "right-aligned"));
};

export const onBulletedListClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "unordered-list-item"));
};

export const onNumberedListClick = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
};

export const onUndo = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  const newState = EditorState.undo(editorState);
  setEditorState(newState);
};

export const onRedo = (editorState: EditorState, setEditorState: React.Dispatch<React.SetStateAction<EditorState>>) => {
  const newState = EditorState.redo(editorState);
  setEditorState(newState);
};

export const convertTo24HourFormat = (time12hr: string): string => {
  const [time, modifier] = time12hr.split(" "); // Split into time and AM/PM
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours < 12) {
    hours += 12; // Convert PM hours
  } else if (modifier === "AM" && hours === 12) {
    hours = 0; // Convert 12 AM to 00:00
  }

  // Format the hours and minutes to two digits (e.g., "09", "05")
  const hours24 = hours.toString().padStart(2, "0");
  const minutesFormatted = minutes.toString().padStart(2, "0");

  return `${hours24}:${minutesFormatted}`;
};

export const addLinkRef = (linkURL: string, editorState: any) => {
  if (linkURL.trim()) {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    // Create the link entity
    const contentStateWithEntity = contentState.createEntity(
      "LINK", // Entity type
      "MUTABLE", // Entity mutation type (use IMMUTABLE if you don't need to update the URL)
      { url: linkURL }, // Entity data (URL)
    );

    // Get the entity key for the link
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply the entity to the selected text (whether it's within one block or across multiple)
    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey);

    // Push the updated content state to the editor state
    const newEditorState = EditorState.push(editorState, contentStateWithLink, "apply-entity");

    return newEditorState;
  }
};
