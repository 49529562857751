import { Input } from 'antd';
import { debounce } from 'lodash';

interface AnnouncementSearchProps {
  setSearchAnnouncement: (searchTerm: string) => void;
}

export default function AnnouncementSearch({ setSearchAnnouncement }: AnnouncementSearchProps) {
  const onSearch = debounce((searchTerm: string) => {
    setSearchAnnouncement(searchTerm);
  }, 500);

  return (
    <div>
      <Input
        placeholder="Search"
        className="search-announcement"
        onChange={(e) => onSearch(e.target.value)}
      />
      <i className="fi fi-rr-search"></i>
    </div>
  );
}
