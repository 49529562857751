import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DownOutlined } from '@ant-design/icons';
import ImagesComponent from '../Images/Images';
import allAboardLogo from '../../assets/images/profile.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEmployeeTable } from '../../redux/employeetable/employeeTableAction';
import StepTracker from './StepTracker/StepTracker';
import type { TableRowSelection } from 'antd/es/table/interface';
import {
    debounce, useGetApiRequests, useNavigate, initLoadData, employeeFilterApiCall,
    handleExpand, searchEmployeeByName, storeData, triggerNotification,
    ErrorMessages
} from '../../common/CommonExport';
import DeleteConfirmationModal from '../../pages/Organization/DeleteConfirmationModal';
import { DataType } from '../../services/apiInterfaceType/apiInterfaceType';
import CustomFilterDropdown from './CustomFilterDropdown';
import Loader from '../Loader/Loader';
import { clearTableFilter } from '../../redux/slices/tableFilterSlice';
import axios from 'axios';
import { setDeprovision } from '../../redux/slices/updateProfileSlice';
import { setAppraisalTab } from '../../redux/slices/updateProfileSlice';

const EmployeeTable = (props: any) => {
    const { dashboardData } = props;
    const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;
    const dispatch = useDispatch();
    const tableReload = useSelector((state: any) => state?.tableFilter?.filterValue);
    const EmployeeFilter = useGetApiRequests('dashboard', 'GET');
    const deleteEmployee = useGetApiRequests('deleteEmployee', 'DELETE');
    const documentStatus = useGetApiRequests('documentStatus', 'GET');
    const [isExpand, setIsExpand] = useState(true);
    const [employeeData, setEmployeeData] = useState([]);
    const [employeeDataSearch, setEmployeeDataSearch] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
    const [expandedData, setExpandedData] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState<any>(null);
    const [allFilterValues, setAllFilterValues] = useState<any>({});
    const [deleteEmpId, setDeleteEmpId] = useState<any>([]);
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [lastFilter, setLastFilter] = useState('All Employees');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loader, setLoader] = useState<any>(false)
    const [expand, setexpand] = useState<any>(false)
    const navigate = useNavigate();
    const { Search } = Input;
    const [paginationConfig, setPaginationConfig] = useState({
        pageSize: pageSize,
        current: currentPage,
        total: employeeDataSearch.length,
        onChange: (page: any, pageSize: any) => {
            setCurrentPage(page);
            setPageSize(pageSize);
        },
    })

    const handelExpand = () => {
        setIsExpand(prevState => !prevState);
        dispatch(toggleEmployeeTable(isExpand));
    };
    const handelProfile = useCallback((record: any, employeeTypeId: any, employeeName: any,) => {
        navigate(`/employee/details/${record}?employeeTypeId=${employeeTypeId}&employeeName=${employeeName}`);
        storeData("employeeId", record, true);
        dispatch(setDeprovision(false));
        dispatch(setAppraisalTab(false));

    }, [navigate]);

    const onSearch = debounce((searchTerm: string) => {
        const filteredEmployees = searchEmployeeByName(employeeData, searchTerm);
        setEmployeeDataSearch(filteredEmployees);
    }, 500);
    useEffect(() => {
        if (tableReload) {
            employeeFilterApiCall(tableReload, EmployeeFilter, setAllFilterValues, setEmployeeData, setEmployeeDataSearch, setLoader);
        }
    }, [tableReload])

    useEffect(() => initLoadData(dashboardData, setAllFilterValues, setEmployeeData, setEmployeeDataSearch, dispatch), [dashboardData, props.reLoadPage]);

    const columns: ColumnsType<DataType> = useMemo(() => {
        return [
            {
                title: ' Employee Name',
                dataIndex: 'employeename',
                width: '21%',
                sorter: (a, b) => a.employeename.localeCompare(b.employeename),
                filterIcon: <DownOutlined />,
                render: (text: any, record: any) => {
                    return (
                        <div className='employee-image flex items-center justify-start'>
                            <div className='employee-image_container'>
                                <ImagesComponent alt='logo' src={record?.profileUrl ? `${imageBaseUrl}${record?.profileUrl}` : allAboardLogo} className='employee_image' />
                            </div>
                            <p className='cursor-pointer ' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)} >{text}</p>
                        </div>
                    );
                },
            },
            {
                title: 'DOJ',
                dataIndex: 'doj',
                sorter: (a, b) => {
                    const dateA: any = new Date(a.doj.split('-').reverse().join('-'));
                    const dateB: any = new Date(b.doj.split('-').reverse().join('-'));
                    return dateA - dateB;
                },
                width: '10%',
                render: (text, record) => (
                    <p className='cursor-pointer' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)}>
                        {text ?? '-'}
                    </p>
                ),
            },
            {
                title: 'Grade',
                dataIndex: 'grade',
                filters: allFilterValues?.grades,
                onFilter: (value: any, record: any) => {
                    return record.grade === value;
                },
                filterSearch: true,
                width: '15%',
                filterIcon: (filtered: boolean) => FilterIcon({ filtered, columnTitle: 'Grade' }),
                render: (text, record) => (
                    <p className='cursor-pointer' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)}>
                        {text ?? '-'}
                    </p>
                ),
            },
            {
                title: 'Dept',
                dataIndex: 'dept',
                filters: allFilterValues?.dept,
                onFilter: (value: any, record: any) => {
                    return record.dept === value;
                },
                filterSearch: true,
                width: '15%',
                filterIcon: (filtered: boolean) => FilterIcon({ filtered, columnTitle: 'Dept' }),
                render: (text, record) => (
                    <p className='cursor-pointer' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)}>
                        {text ?? '-'}
                    </p>
                ),
            },
            {
                title: 'Designation',
                dataIndex: 'designation',
                filters: allFilterValues?.designation,
                onFilter: (value: any, record: any) => {
                    return record.designation === value;
                },
                filterSearch: true,
                width: '15%',
                filterIcon: (filtered: boolean) => FilterIcon({ filtered, columnTitle: 'Designation' }),
                render: (text, record) => (
                    <p className='cursor-pointer custom-cell-class 
' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)}>
                        {text ?? '-'}
                    </p>
                ),
            },

            {
                title: 'Reporting to',
                dataIndex: 'reportingTo',
                filters: allFilterValues?.reportingTo,
                onFilter: (value: any, record: any) => {

                    let records: any = record.reportingTo === value;
                    getPagination(records)
                    return records
                },
                filterSearch: true,
                width: '15%',
                filterIcon: (filtered: boolean) => FilterIcon({ filtered, columnTitle: 'Reporting to' }),
                render: (text, record) => (
                    <p className='cursor-pointer' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)}>
                        {text ?? '-'}
                    </p>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                filters: allFilterValues?.status,
                filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <CustomFilterDropdown setLoader={setLoader} filters={filters} setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters}
                        setLastFilter={setLastFilter} EmployeeFilter={EmployeeFilter} setAllFilterValues={setAllFilterValues} setEmployeeData={setEmployeeData} setEmployeeDataSearch={setEmployeeDataSearch} reLoadPage={props?.reLoadPage} />
                ),
                filterSearch: true,
                width: '13%',
                filterIcon: (filtered: boolean) => FilterIcon({ filtered, columnTitle: 'Status' }),
                render: (text, record) => {
                    return (
                        <div className='employee-image flex items-center justify-start'>
                            <p className='cursor-pointer' onClick={() => handelProfile(record?.key, record?.employeeTypeId, record?.employeename)}>{text ?? '-'}</p>
                            <div className='w-16 md:w-32'></div>
                        </div>
                    );
                },
            },
        ]
    }, [allFilterValues?.grades, allFilterValues?.dept, allFilterValues?.designation, allFilterValues?.reportingTo, allFilterValues?.status, handelProfile, EmployeeFilter]);

    const rowSelection: TableRowSelection<DataType> = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDeleteEmpId(selectedRowKeys);
        },
    };
    let totalEmployees: any = []

    const cc = (record: any) => {
        if (record) {
            totalEmployees.push(record)
        }
        var wholeNumber = Math.floor(totalEmployees.length / 10);
        var decimalPart = (totalEmployees.length / 10) - wholeNumber;
        if (decimalPart >= 0.1) {
            return wholeNumber + 2;

        } else {
            return wholeNumber + 1;
        }
    }
    const getPagination = (record: any) => {
        let vv: any = cc(record)
    }
    const getRowClassName = (record: any) => {
        return record.reviewStatus === 'Pending' ? 'highlighted-row' : '';
    };
    const expandedRowRender = (record: any) => {
        if (record?.key === expandedRowKey) {
            return (
                <>
                    {expandedData?.data?.documentNames.map((stepper: any, index: any) => {
                        const descriptions: any = [];
                        const currentStatus = expandedData?.data?.employeeFootPrintDto
                        let result = 0;
                        for (const item of currentStatus) {
                            if (item?.discriminator?.toLowerCase().includes(stepper?.name?.toLowerCase()) || item?.description === 'Employee information saved')
                                descriptions.push(item.description);
                        }
                        if (descriptions.includes('Document signed off')) result = 3
                        else if (descriptions.includes('Document sent')) result = 2
                        else if (descriptions.includes('Employee information saved')) result = 1
                        return (
                            <StepTracker key={`${stepper?.templateCategoryId}`} currentData={stepper} currentStep={result} setCurrentStep={setCurrentStep} record={record} />
                        )
                    })}
                </>
            );
        }
        return null;
    };

    const handleDeleteEmp = () => {
        setDeletePopUp(true);
        if (deleteEmpId?.length === 1) setDeleteMessage('Are you sure want to delete this employee?');
        else if (deleteEmpId?.length > 1) setDeleteMessage(`Are you sure want to delete these ${deleteEmpId?.length} employees?`);
    };

    const handleCloseDelete = () => setDeletePopUp(false);

    const handleDeleteSelected = () => {
        const queryParamString = deleteEmpId.map((id: any) => `employeeIds=${id}`).join('&');
        const finalUrl = process.env.REACT_APP_ELMS_BASEURL + `/elms-app-api/v1/employee/remove-from-course-and-learning-path?` + queryParamString
        axios.delete(finalUrl)
            .then((response: any) => {
                if (response.status === 200) {
                    deleteEmployee(deleteEmpId)
                        .then((res: any) => {
                            employeeFilterApiCall(lastFilter, EmployeeFilter, setAllFilterValues, setEmployeeData, setEmployeeDataSearch, setLoader);
                            setDeleteEmpId([])
                        })
                        .catch((err: any) => {
                            console.error(err);
                        });
                } else {
                    triggerNotification('error', '', ErrorMessages.employeeDelete, 'topRight')
                }
            })
            .catch((err: any) => {
                triggerNotification('error', '', err?.message, 'topRight')
            });

    };



    const handelExpandSearch = () => {
        setexpand(!expand)
    }
    const setCurrent = (e: any) => {
        setPaginationConfig({
            ...paginationConfig,
            current: e.current
        })

    }
    return (
        <div className='employee-table z-[1] responsive'>
            {loader && <Loader />}
            <div className='birth-anniversary__tab flex justify-between relative '>
                <div className='card-title justify-start flex'>
                    <h4>Employees</h4>
                </div>
                <div className='flex justify-end relative top-[-9px]' >
                    <div className='search-field pr-[10px]'>
                        <div className="search-filter mr-4">
                            <Input
                                placeholder="Search"
                                className="search"
                                onChange={(e: any) => onSearch(e.target.value)}
                            />
                            <span className="material-symbols-outlined"> search </span>
                        </div>
                    </div>
                    {/* <div className="search-box mb-4 mr-5 flex">
                        <input className={expand ? "search-txt text-black search-box_hover" : "search-txt text-black "} type="text" name="" placeholder="Search" onChange={e => onSearch(e.target.value)} />
                        <button className="search-btn" onClick={handelExpandSearch}>
                            <span className="material-symbols-outlined">
                                search
                            </span>
                        </button>
                    </div> */}
                    {deleteEmpId?.length > 0 && (
                        <>
                            <button className='w-10 h-10 mr-3 deleteIcon rounded-full flex items-center justify-center' onClick={() => handleDeleteEmp()}><span className="material-symbols-outlined">
                                delete
                            </span></button>
                            {deleteEmpId?.length === 1 && <button className=' hidden mr-3 w-9 h-9 rounded-full flex items-center justify-center employee-deprovision p-5'><span className="material-symbols-outlined">
                                person_add
                            </span></button>}
                        </>
                    )}
                    <div className='expand expand-icon justify-end text-primary cursor-pointer mt-1'>
                        <span className='material-symbols-outlined text-lg' onClick={handelExpand}>
                            open_in_full
                        </span>
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                rowSelection={{ ...rowSelection }}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                    expandIcon: CustomExpandIcon,
                    onExpand: (expanded, record) => {
                        if (expanded)
                            handleExpand(record, documentStatus, setCurrentStep, setExpandedData, setExpandedRowKey, expandedRowKey, setLoader)
                    },
                }}
                dataSource={employeeDataSearch}
                // pagination={paginationConfig}
                pagination={{
                    showSizeChanger: true,
                }}
                rowClassName={getRowClassName}
                onChange={(e) => {
                    setCurrent(e)
                    const employeeTags: any = document.getElementsByClassName("ant-table-content")
                    employeeTags[0].scrollTop = 0
                }}
            />
            {deletePopUp && <DeleteConfirmationModal open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
        </div>
    );
};
const FilterIcon = ({ filtered, columnTitle }: any) => (
    <div>
        {columnTitle === 'Status' ? <span className="filter-dot filterStatus"></span> : filtered && <span className="filter-dot filterStatus"></span>}
        <span className='material-symbols-outlined expand-icon'>expand_more</span>
    </div>
);
const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    if (!(record?.status !== 'Probation Extension' && record?.status !== 'Probation'))
        return expanded ? (
            <span className='material-symbols-outlined expand-icon' onClick={e => onExpand(record, e)}>
                expand_less
            </span>
        ) : (
            <span className='material-symbols-outlined expand-icon' onClick={e => onExpand(record, e)}>
                expand_more
            </span>
        );
};

export default EmployeeTable;
