import React, { useEffect, useState } from "react";
import CourseBreadCrums from "./CourseBreadCrums";
import { Select, Tooltip } from "antd";
import { courseStatusSelect, naviPaths } from "../../Organization/TableColumn";
import { triggerNotification, useGetApiRequests, useNavigate } from "../../../common/CommonExport";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import DeleteConfirmationModal from "../../Organization/DeleteConfirmationModal";
import { setCourseDetails } from "../../../redux/slices/currentCourseDetailSlics";
import "./AddChapterModal.scss"

const CourseDetailsHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const changeMode = useGetApiRequests('updateCourse', 'PUT');
  const getCourseById = useGetApiRequests('getCourseById', 'GET');
  const deleteCourseById = useGetApiRequests('updateCourse', 'DELETE');
  const currentCourseId = useSelector((state: any) => state?.currentCourse?.courseId);
  const employeeDetail = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const chapterListByCourseId = useGetApiRequests('chapterListByCourseId', 'GET');
  const currentCourseDetails = useSelector((state: any) => state?.currentCourse?.courseDetails);
  const [courseStatus, setCourseStatus] = useState('draft')
  const [loader, setLoader] = useState<boolean>(false)
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<any>('Are you sure to delete this course?')
  const [contentChange, setContentChange] = useState<boolean>(false);

  const handleChangeStatus = async (value: any) => {
    setLoader(true)
    const pathParams = { id: currentCourseId, endPoint: 'status' }
    try {
      const response: any = await changeMode('', { courseStatus: value }, pathParams)
      setLoader(false)
      const empDetailsUpdate = {
        ...employeeDetail,
        courseStatus: value
      }
      dispatch(setCourseDetails(empDetailsUpdate))
      setCourseStatus(value)
      triggerNotification('success', '', response?.data?.message, 'topRight');
    } catch (err: any) {
      setLoader(false)
      triggerNotification('error', '', err?.response?.data?.data ?? 'Something went wrong', 'topRight');
    }
  }

  const handleChangeMode = async (value: any) => {
    setLoader(true);
    try {
      const pathParams = { id: currentCourseId };
      const res: any = await chapterListByCourseId('', {}, pathParams);
      const reformedEmployeeData = (res?.data?.data || [])
        .filter((item: any) => item?.chapter !== null)
      if (reformedEmployeeData?.length > 0 || value === 'draft') {
        handleChangeStatus(value)
      } else {
        setDeleteMessage("Add Chapters to Publish.")
        setContentChange(true)
        setDeletePopUp(true)
      }
      setLoader(false);
    } catch (err: any) {
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
      setLoader(false);
    }
  }

  const handleDeleteCourseById = async () => {
    setLoader(true);
    try {
      const response: any = await deleteCourseById('', {}, { courseId: currentCourseId })
      if (response?.data?.status === 200) {
        triggerNotification('success', '', response?.data?.message, 'topRight');
        navigate(`/home/dashboard/learning-management`);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      triggerNotification('error', '', err?.response?.data?.data, 'topRight');
    }
  }

  const handleDeleteSelected = () => {
    if (currentCourseDetails?.employeeCount === 0 && currentCourseDetails?.courseStatus === 'draft' && deleteMessage?.includes('Are you sure to delete this course?'))
      handleDeleteCourseById()
  };

  const handleCourseDetils = async () => {
    try {
      setLoader(true);
      const pathParams = { id: currentCourseId };
      const res: any = await getCourseById('', {}, pathParams);
      setCourseStatus(res?.data?.data?.status)
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };
  const handleDeleteCourse = () => {
    if (currentCourseDetails?.employeeCount === 0 && currentCourseDetails?.courseStatus === 'draft') {
      setDeleteMessage('Are you sure to delete this course?')
      setContentChange(false)
    }
    else {
      setContentChange(true)
      setDeleteMessage('Please verify if the course is assigned to any employee. If not, switch it to draft mode to enable editing or deletion.')
    }
    setDeletePopUp(true)
  }
  const handleCloseDelete = () => setDeletePopUp(false);
  const handleAssignedEmployee = () => {
    navigate(`/home/dashboard/learning-management/assignedemployee/${currentCourseId}`);
  }

  useEffect(() => {
    handleCourseDetils()
    const element = document.querySelector('.ant-select-selection-item');

    // Check if the element exists before modifying its attributes
    if (element) {
      // Remove the title attribute
      element.removeAttribute('title');
    }
  }, [])
  return (
    <div className="flex justify-between px-[3.5rem] pt-8">
      {loader && <Loader />}
      <CourseBreadCrums naviPaths={naviPaths} />
      < div className="flex justify-center items-center">
        <Select
          className='float-control w-[150px] h-[45px]' value={courseStatus}
          onChange={(value) => handleChangeMode(value)}>
          {courseStatusSelect?.map((item: any) => (
            <Select.Option key={item?.value} value={item?.value} title="">
              {item?.text}
            </Select.Option>
          ))}
        </Select>
        <Tooltip placement="bottom" title="Delete Course">
          <button className="material-symbols-outlined delete-btn cursor-pointer mx-3" onClick={() => handleDeleteCourse()}>
            delete
          </button>
        </Tooltip>
        <Tooltip placement="bottom" title="Assigned Employees">
          <button className="material-symbols-outlined bg-primary text-white cursor-pointer p-2 rounded-full" onClick={() => handleAssignedEmployee()}>
            groups
          </button>
        </Tooltip>
      </div>
      {deletePopUp && <DeleteConfirmationModal contentChange={contentChange} open={deletePopUp} handleDeleteSelected={handleDeleteSelected} handleCloseDelete={handleCloseDelete} type='Delete' deleteMessage={deleteMessage} />}
    </div>
  )
}

export default CourseDetailsHeader