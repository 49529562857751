import { Modal, Radio } from 'antd';
import React, { useState } from 'react';

type Props = {
  title: string;
  setEditLink: (editLink: boolean) => void;
  editLink: boolean;
};

const EditLinkModal: React.FC<Props> = (props) => {
  const { title, setEditLink, editLink } = props;

  const [value, setValue] = useState(1);

  const handleClose = () => {
    setEditLink(false);
  };

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Modal
        title={title}
        className="EditLink-modal"
        centered
        open={editLink}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
        width={650}
        maskClosable={false}
      >
        <div>
          <div className="flex space-x-2 py-4 border-b">
            <p className="text-[#D34A7C] font-rubik text-[17px]">Text to display:</p>
            <input
              type="text"
              name="displayText"
              id="displayText"
              className="underline border-b focus:outline-none focus:shadow-outline text-[16px] font-rubik font-medium"
            />
          </div>
          <div className="flex space-x-2 py-3 items-center">
            <p className="text-[#D34A7C] font-rubik text-[17px]">Link to:</p>
            <div className="space-x-2 flex items-center">
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Web address</Radio>
                <Radio value={2}>Email address</Radio>
              </Radio.Group>
            </div>
          </div>

          {value === 1 && (
            <div className="py-2 web-address-content">
              <p className="text-[17px] font-normal font-rubik">
                To what URL should this link go?
              </p>
              <input
                type="url"
                name="webAddress"
                id="webAddress"
                className="underline border text-[#707070] bg-[#EFEFEF] focus:outline-none focus:shadow-outline text-[17px] font-rubik font-medium w-full"
              />
            </div>
          )}

          {value === 2 && (
            <div className="py-2 email-address-content">
              <p className="text-[17px] font-normal font-rubik">
                To what email address should this link?
              </p>
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                className="underline border text-[#707070] bg-[#EFEFEF] focus:outline-none focus:shadow-outline text-[17px] font-rubik font-medium w-full"
              />
            </div>
          )}

          <div className="py-2">
            <button
              type="button"
              className="underline text-[#D34A7C] font-rubik text-[16px] cursor-pointer"
            >
              Test the link
            </button>
          </div>
          <div className="mt-6 flex justify-end mb-2">
            <button
              className="cursor-pointer bnt-secondary text-[#D23B7D]  border border-[#D23B7D]  h-[45px] rounded-3xl text-[18px] mr-4 secondary w-[90px]"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="cursor-pointer text-white bg-[#D23B7D]   h-[45px] rounded-3xl text-[18px]mr-4 w-[90px]"
              onClick={() => {
                handleClose();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditLinkModal;
