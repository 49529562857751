import React, { useState } from 'react';
import ChapterTable from './ChapterTable';
import { useDispatch } from 'react-redux';
import { setCurrentTab } from '../../../../redux/slices/courseSlice';
import { clearChapterInEdit } from '../../../../redux/slices/currentCourseDetailSlics';

const AddChapter = () => {
  const [chapterLists, setChapterLists] = useState<[]>([])
  const dispatch = useDispatch()
  const handleAddChapter = () => {
    dispatch(clearChapterInEdit())
    dispatch(setCurrentTab(6))
  }
  const handleAssignChapter = () => { dispatch(setCurrentTab(3)) }

  const handleBack = () => { dispatch(setCurrentTab(1)) }
  const handleSwitchToNextSection = () => { dispatch(setCurrentTab(4)) }


  return (
    <>
      <ChapterTable setChapterLists={setChapterLists} />
      <div className="footer add-chapter-footer">
        <div className='my-5 flex justify-between pr-[30px] pl-[38px]'>
          <div className="btnGroup flex">
            <button className=' mr-4 primary px-5  _square flex justify-center items-center ' type="submit" onClick={handleAddChapter} > <span className="material-symbols-outlined">
              add
            </span>Add Chapter</button>
            <button className=' mr-4 primary px-5 _square flex justify-center items-center ' type="submit" onClick={handleAssignChapter} > <span className="material-symbols-outlined">
              docs_add_on
            </span>Assign Chapter</button>
          </div>
          <div className="btnGroup flex justify-end items-end">
            <button className='addButton mr-4 primary px-5   cancelElms' type="submit" onClick={handleBack} >Back</button>
            <button
              className={`addButton primary px-5 confirmElms ${chapterLists?.length === 0 ? 'opacity-50 cursor-not-allowed' : 'opacity-100'}`}
              type="submit"
              disabled={chapterLists?.length === 0}
              onClick={handleSwitchToNextSection}
            >
              Save & Next
            </button>

          </div>
        </div>
      </div>
    </>
  )
}

export default AddChapter
