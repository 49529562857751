import { Checkbox, CheckboxProps } from 'antd'
import React, { useState, useEffect } from 'react';
import {
  addAnnouncementId,
  clearAnnouncementIdList,
  removeAnnouncementId,
} from '../../../../redux/slices/announcementIdSlice';
import { useDispatch, useSelector } from 'react-redux';

interface MailSelectProps {
  data: any; // Replace `any` with a proper type if possible
  handleSelectMail: any,
  status: string
}

export default function MailSelect({ data, handleSelectMail, status, markAll }: any) {
  // Local state to manage the checkbox status
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  const announcementSlice = useSelector((state: any) => state.announcementIdSlice);
  const announcementIdList = announcementSlice.announcementIdList;

  // useEffect(() => {
  //   // If the announcementIdList is empty, reset the checkbox state
  //   if (announcementIdList.length === 0) {
  //     setIsChecked(false);
  //   }
  // }, [announcementIdList]); // Dependency on both checked and announcementIdList
  useEffect(() => {
    if (markAll) {
      setIsChecked(true);
      if (!announcementIdList.includes(data.emailAnnouncementId)) {
        dispatch(addAnnouncementId(data.emailAnnouncementId));
      }
    } else if (announcementIdList.length === 0) {
      setIsChecked(false);
      dispatch(clearAnnouncementIdList());
    } else {
      console.log("mark all 3")
      setIsChecked(false);
      dispatch(clearAnnouncementIdList());
    }
  }, [markAll, data.emailAnnouncementId, dispatch]);

  const onChange: CheckboxProps['onChange'] = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked); // Update local state

    if (!isChecked) {
      // Add the ID to the Redux store
      dispatch(addAnnouncementId(data.emailAnnouncementId));
    } else {
      // Remove the ID from the Redux store
      dispatch(removeAnnouncementId(data.emailAnnouncementId));
    }

  };

  return <Checkbox onChange={onChange} checked={isChecked} ></Checkbox>;
}